import React, { useState, useEffect, useRef } from 'react';



const DButton = ({ className, onClick, children,playSound }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const audioRef = useRef(null);


  return (
    <div
      className={className}
      onMouseEnter={() =>  playSound('hover')}
      onClick={() => {
          playSound('click');
          onClick();
      }}
    >
      {children}
    
    </div>
  );
};

export default DButton;
