import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect } from "react";
import DButton from "./DButton";

import { useSelector, useDispatch } from "react-redux";
import { updateLevel } from "../actions/userActions";
import { Howl, Howler } from "howler";

export default function Scores(props) {
  const { currentLevel, playSound, nextLevel, setHasScore, goHome } = props;

  const levelStats = useSelector((state) => state.levelStats);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateLevel(currentLevel, {
        attempts: levelStats.attempts,
        rating: levelStats.rating,
        time_taken: levelStats.time,
        played_on: new Date(),
      })
    );

    var sound = new Howl({
      src: [`https://assets.dhvij.com/sfx/scores.mp3`],
    });
    sound.stop();
    sound.play();
  }, []);
  return (
    <React.Fragment>
      <AnimatePresence>
        <div className="position-fixed w-100 h-screen appStopper">
          {!props.isTransitioning && (
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              exit={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: 0.5, type: "spring", stiffness: 100 }}
              className="dialog score d-flex flex-column align-items-center"
            >
              {/* <p>Time: {levelStats.time}</p>
      <p>Total: {levelStats.total}</p> */}

              <div className="position-relative">
                <div className=" star-board">
                  <div className={`stars r${levelStats.rating}`}></div>
                </div>
              </div>
              <div className="text">WIN</div>

              <div className="panel">Attempts : {levelStats.attempts + 1}</div>
              {/* <div className='panel'>
                            Accuracy : {3}
                </div> */}

              {/* <DButton playSound={playSound} onClick={()=>{setHasScore(false)}} className="dialog-button circle mini cancel" ></DButton> */}

              <div className="mt-auto d-flex flex-row justify-content-between w-100 align-items-center">
                <DButton
                  playSound={playSound}
                  onClick={() => {
                    goHome();
                    setHasScore(false);
                  }}
                  className="circle mini menu"
                ></DButton>
                <DButton
                  playSound={playSound}
                  onClick={() => {
                    nextLevel(false);
                    setHasScore(false);
                  }}
                  className="circle  next"
                ></DButton>

                <DButton
                  playSound={playSound}
                  onClick={() => props.setHasScore(false)}
                  className="circle mini restart"
                ></DButton>
              </div>

              <div className="mt-2 d-flex flex-row justify-content-center w-100 align-items-center"></div>
            </motion.div>
          )}
        </div>
      </AnimatePresence>
    </React.Fragment>
  );
}
