import React, { useState, useEffect,useRef } from 'react';
import './App.css';

function ScrollingZAxis() {
   
  
  return (
    <div className='body'>
    <div class="wrapper">
  <div class="slides">
    <div class="slide">
      <div class="title">Pure CSS z-scrolling</div>
    </div>
    <div class="slide">
      <div class="title">Slide 1</div>
    </div>
    <div class="slide">
      <div class="title">Slide 2</div>
    </div>
    <div class="slide">
      <div class="title">Slide 3</div>
    </div>
    <div class="slide">
      <div class="title">Slide 4</div>
    </div>
    <div class="slide">
      <div class="title">Slide 5</div>
    </div>
  </div>
</div>
</div>


  )
}

  export default ScrollingZAxis;