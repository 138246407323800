import React, { useEffect, useState } from "react";
import DButton from "./DButton";
import { ScrollContainer, HorizontalSection } from "react-nice-scroll";
import { InView } from "react-intersection-observer";
import Background from "./Background";

import { useSelector } from "react-redux";

export default function Menu(props) {
  const [levels, setLevels] = useState(props.levels);
  const { onClick, playSound } = props;
  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    setLoading(false);
    //  console.log(user.levels)
  }, []);

  const [visibleIndexes, setVisibleIndexes] = useState(() =>
    Array.from({ length: levels.length }, () => false)
  );

  const handleChange = (inView, entry, index) => {
    if (inView && !visibleIndexes[index]) {
      setVisibleIndexes((prevVisibleIndexes) => {
        const newVisibleIndexes = [...prevVisibleIndexes];
        newVisibleIndexes[index] = true;
        return newVisibleIndexes;
      });
    } else if (!inView && visibleIndexes[index]) {
      setVisibleIndexes((prevVisibleIndexes) => {
        const newVisibleIndexes = [...prevVisibleIndexes];
        newVisibleIndexes[index] = false;
        return newVisibleIndexes;
      });
    }
  };

  return (
    <React.Fragment>
      <div className="h-100 w-100">
        <div className="level appStopper">
          {!loading && (
            <div className="level-container  overflow-hidden level-bg">
              <img
                src="//data.dhvij.com/images/frames/level-bg.svg"
                className="pe-none"
              />
              <div className="position-absolute overflow-y-auto w-100 menu-bg">
                <div className=" menu-grid" style={{ overflowY: "auto" }}>
                  {levels &&
                    levels.map((e, i) => {
                      let rating = 0;
                      rating = Object.keys(user.levels).includes(
                        e.level.toString()
                      )
                        ? user.levels[e.level].rating
                        : "";

                      return (
                        <InView
                          className={`${visibleIndexes[i] ? "visible" : ""}`}
                          delay={100}
                          threshold={0.5}
                          key={`inview-${i}`}
                          onChange={(inView, entry) =>
                            handleChange(inView, entry, i)
                          }
                        >
                          <div
                            style={{ position: "relative" }}
                            className="w-100 star-board"
                          >
                            <div
                              key={i}
                              data-level={e.level}
                              onMouseEnter={() => props.playSound("hover")}
                              onClick={(e) => {
                                props.playSound("click");
                                onClick(e);
                              }}
                              className={`btn level`}
                            >
                              {i + 1}
                            </div>

                            <div className={`stars r${rating}`}></div>
                          </div>
                        </InView>
                      );
                    })}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
