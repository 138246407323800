import React, { useEffect } from 'react'
import { motion } from 'framer-motion'
export default function PageTransition(props) {
    
    const {setIsLoading} = props


    useEffect(()=>{
       let counter =  setTimeout(()=>{
        setIsLoading(false)
        clearTimeout(counter)

       },400)
    },[])

    return (
        <>
        <div className='w-100 h-100 position-fixed' style={{zIndex:1000}}>
            <div style={{width:'100%',height:'100%', transform:'scale(1)'}}>
<motion.div
className='position-absolute w-100 h-100'
style={{
    //  transformOrigin:'100% 0%',
    //  translate:'100% 100%', 
    // scale:'3'
    opacity:1,
    background:'#fff'
}}

// initial={{ opacity: 1, scale:'2', visibility: "hidden" }}
//  exit={{ opacity: 0,  scale:'2',visibility: "hidden" }}

animate={{ 
     opacity: 0, 
}}
transition={{
    delay: 0.1,
    type: "tween",
    duration: 0.5
}}

>
{/* <motion.div className='w-100 h-100 position-fixed' 

style={{
     scale:'2',
    background:'url(images/transition.jpg)',
    backgroundPosition:'center center',
    backgroundSize:'cover'
}}

initial={{ scale: '1' }}
transition={{type: "tween",duration: 0.5}}
>

</motion.div> */}


</motion.div>
</div>
</div>

        </>
    )
}
