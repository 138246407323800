import React, { forwardRef } from "react";

const audioFiles = [
  { name: "hover", path: "https://assets.dhvij.com/sfx/hover.mp3" },
  { name: "click", path: "https://assets.dhvij.com/sfx/click.mp3" },
  { name: "correct", path: "https://assets.dhvij.com/sfx/correct.mp3" },
  { name: "wrong", path: "https://assets.dhvij.com/sfx/wrong.mp3" },
  { name: "win", path: "https://assets.dhvij.com/win/win.mp3" },

  // add more audio files as needed
];

const winsound = {
  0: [0, 2.2320181405895694],
  1: [4, 6.76],
  2: [8, 10.184013605442177],
  3: [12, 14.256009070294784],
  4: [16, 18.544013605442178],
  5: [20, 22.08800453514739],
  6: [24, 26.328004535147393],
  7: [28, 30.424013605442177],
  8: [32, 34.4],
  9: [36, 39.096009070294784],
  10: [41, 43.08800453514739],
  11: [45, 46.92],
  12: [48, 50.28],
  13: [52, 54.71201814058957],
  14: [56, 59.096009070294784],
};

const AudioManager = forwardRef((props, ref) => {
  const audioCacheRef = React.useRef({});

  const loadSound = (path) => {
    if (audioCacheRef.current[path]) {
      // audio already loaded, nothing to do
      return;
    }

    const audio = new Audio(path);
    audioCacheRef.current[path] = audio;
    audio.addEventListener("canplaythrough", () => {
      // audio file has finished loading and can be played
      // console.log(`Audio file ${path} loaded`);
    });
    audio.load();
  };

  // preload all audio files on mount
  React.useEffect(() => {
    audioFiles.forEach((file) => loadSound(file.path));
  }, []);

  const playSound = (soundName) => {
    const audio =
      audioCacheRef.current[
        audioFiles.find((file) => file.name === soundName)?.path
      ];
    if (!audio) {
      console.warn(`Audio file for ${soundName} not found`);
      return;
    }
    audio.currentTime = 0;
    if ("win" == soundName) {
      const min = 1;
      const max = 15;
      let num = Math.floor(Math.random() * (max - min) + min);
      audio.currentTime = winsound[num][0];
      let time = winsound[num][1] - winsound[num][0];
      setTimeout(() => {
        audio.pause();
      }, 1000 * time);
    }
    audio.pause();
    audio.play();
  };

  React.useImperativeHandle(ref, () => ({
    playSound,
  }));

  return <>{/* Audio files are preloaded and available for use */}</>;
});

export default AudioManager;
