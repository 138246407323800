import { CONSUME_ENERGY, REFILL_ENERGY } from './userActions';

export const useEnergy = (amount) => ({
  type: CONSUME_ENERGY,
  payload: amount,
});

export const refillEnergy = (energy) => ({
  type: REFILL_ENERGY,
  payload: energy,
});

export const startEnergyRefillTimer = () => (dispatch, getState) => {
  const startTime = Date.now();
  const refillInterval = setInterval(() => {
    const { energy } = getState();
    const currentTime = Date.now();
    const elapsedTime = currentTime - startTime;
    const energyToRefill = Math.floor(elapsedTime / (2 * 60 * 60 * 1000)); // Refill 1 unit of energy every 5 minutes
    if (energyToRefill > 0) {
      const newEnergy = Math.min(energy + energyToRefill, 100); // Cap the energy at 100

      dispatch(refillEnergy(newEnergy));
      dispatch(saveEnergy(newEnergy));
    }
  }, 60000); // Check every 5 seconds for elapsed time and energy refill

  localStorage.setItem('refillInterval', String(refillInterval));
};


export const consumeEnergy = (amount) => {
  return (dispatch, getState) => {
    const { energy } = getState();
    const remainingEnergy = Math.max(energy - amount, 0);

    dispatch(useEnergy(amount));

    return remainingEnergy;
  };
};
export const saveEnergy = (energy) => {
  localStorage.setItem('energy', JSON.stringify(energy));
};
