// src/reducers/backgroundMusicReducer.js

// Define the initial state
const initialState = {
    backgroundMusic: true
  };
  
  // Define the reducer function
  const backgroundMusicReducer = (state = initialState, action) => {
    switch (action.type) {
      case "TOGGLE_BACKGROUND_MUSIC":
        return { ...state, backgroundMusic: action.payload };
      default:
        return state;
    }
  };
  
  export default backgroundMusicReducer;
  