import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import themes from "./themes";
import { Howl, Howler } from "howler";
import { useSelector, useDispatch } from "react-redux";
import {
  setGameStatus,
  setCurrentLevel,
  setNextLevel,
  setSFXVolume,
  setMusicVolume,
} from "../actions/gameActions";

const BackgroundMusic = ({ theme_id, isFocused }) => {
  const [audio, setAudio] = useState(null);
  const isPlaying = true;
  const volume = 0.4;

  useEffect(() => {
    const mp3 = `https://assets.dhvij.com/music/${theme_id}.mp3`;

    // const mp3 = "https://assets.dhvij.com/sounds/" + currentLevel + "/" + (obj) + ".mp3";
    const sound = new Howl({ src: [mp3], volume: volume });
    // Howler.volume(volume);
    sound.play();
    return () => {
      sound.stop();
      sound.unload();
    };

    // const newAudio = new Audio(audiourl);
    // newAudio.setAttribute('crossorigin', 'anonymous');
    // newAudio.loop = true;
    // newAudio.volume = volume;
    // setAudio(newAudio);
  }, [theme_id, volume]);

  // useEffect(() => {
  //   // console.log(firstInteraction)

  //   if (audio) {
  //     if (isPlaying) {
  //       audio.play();
  //     } else {
  //       audio.pause();
  //     }

  // }
  // }, [isPlaying, audio]);

  return null;
};

const BG = (props) => {
  const { i, img, theme_id } = props;
  const url = `https://assets.dhvij.com/images/theme/${theme_id}/${img}`;

  return (
    <div
      style={{ backgroundImage: `url(${url})` }}
      className={`appscreen layer${i}`}
    ></div>
  );
};

const Background = React.memo((props) => {
  const { theme, firstInteraction, isFocused, isTransitioning } = props;
  const [moving, setMoving] = useState(false);

  const gameSettings = useSelector((state) => state.game);
  // console.log(gameSettings);
  const params = useParams();

  const getNumberById = (id) => {
    const num = id % 16;
    return num === 0 ? 16 : num;
  };
  let randomtheme = -1;
  if (params.id) {
    randomtheme = getNumberById(params.id);
  } else {
    randomtheme = theme;
  }
  // randomtheme = 5;
  let theme_id = randomtheme ? randomtheme : "-1";

  let selectedtheme = Object.values(themes[theme_id]); /*.splice(
    0,
    Object.values(themes[theme_id]).length - 1
  );*/

  useEffect(() => {
    props.setTheme(theme_id);
  }, []);

  // console.log("render bg");

  const { backgroundMusic } = useSelector((state) => state.backgroundMusic);

  return (
    <>
      <div
        className={`appscreen ${
          gameSettings.gameStatus === "transitioning" ? "true" : "false"
        }`}
      >
        {selectedtheme.map((img, i) => {
          return <BG key={i} img={img} i={i} theme_id={theme_id} />;
        })}

        {firstInteraction && isFocused && backgroundMusic && (
          <BackgroundMusic
            isFocused={isFocused}
            theme_id={theme_id}
            firstInteraction={firstInteraction}
          />
        )}
      </div>
    </>
  );
});

export default Background;
