import { createStore, applyMiddleware } from "redux";
import rootReducer from "../reducers";
import { loadState, saveState } from "./localStorage.js";
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { startEnergyRefillTimer } from '../actions/energyActions';

// Load the initial state from local storage (if available)
const persistedState = loadState();

// Create the Redux store with the root reducer, persisted state, and middleware
const store = createStore(
  rootReducer,
  persistedState,
  composeWithDevTools(applyMiddleware(thunkMiddleware))
);

const refillInterval = localStorage.getItem('refillInterval');
if (refillInterval) {
  store.dispatch(startEnergyRefillTimer());
}

// Subscribe to changes in the store and save the state to local storage
store.subscribe(() => {
  saveState({
    backgroundMusic: store.getState().backgroundMusic,
    currentLevel: store.getState().currentLevel,
    sfx: store.getState().sfx,
    levelStats: store.getState().levelStats,
    user: store.getState().user,
  });
});

export default store;
