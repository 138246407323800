import React from 'react'



export default function Planet(props) {
    
    const {id,index,title,cover,onClick} = props
    const {playSound} = props
    const audioManagerRef = React.useRef(null);


    return (
      <React.Fragment>
        <div data-id={id} data-theme={index+1} className='planet' onMouseEnter={()=>{playSound('hover')}} onClick={(e)=>{onClick(e); playSound('click')}} style={{'backgroundImage':`url(https://assets.dhvij.com/images/levels/${index+1}.png)`}}>
           <span className='titles'>{title}</span>
      {/* <img src={cover} /> */}
        </div>
      </React.Fragment>
    )
}
