// Action Types
export const UPDATE_ATTEMPTS = 'UPDATE_ATTEMPTS';
export const UPDATE_TIME = 'UPDATE_TIME';
export const UPDATE_TOTAL = 'UPDATE_TOTAL';
export const RESET = 'RESET';

// Action Creators
export const updateAttempts = () => ({
  type: UPDATE_ATTEMPTS,
});

export const updateTime = (time) => ({
  type: UPDATE_TIME,
  payload: time,
});

export const updateTotal = (total) => ({
  type: UPDATE_TOTAL,
  payload: total,
});

export const reset = () => ({
    type: RESET,
  });
