import React, { useState, useEffect } from "react";

export default function FillBlanks(props) {
  const words = props.data;

  const [currentQuestion, setCurrentQuestion] = useState(0);

  return (
    <>
      <div className=" d-flex flex-row justify-content-start w-100 flex-wrap">
        {words.map((j, count) => {
          return (
            <>
              <div className="d-flex   justify-content-between">
                <div className="text-center thebox">
                  <div className="value">
                    {j.hidden && j.value}
                    {!j.hidden && <input type="number" autoFocus />}
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
      <div className="d-flex flex-row justify-content-between pages">
        {  (
          <div
            className="btn btn-primary"
            onClick={() => setCurrentQuestion(currentQuestion - 1)}
          >
            Previous
          </div>
        ) }
        


          { props.currentSection<props.totalSections-1 &&
          <div
            className="btn btn-primary"
            onClick={() => {
              props.setCurrentSection(props.currentSection + 1);
            }}
          >
            Next Chapter
          </div>
          }

      </div>
    </>
  );
}
