import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Quiz from "./Quiz";

import { motion, AnimatePresence } from "framer-motion";
import DButton from "./DButton";
import { fetchMainMenu, fetchQuiz, fetchLevels } from "../utils/Utils";
// import UserData from "./UserData";

import Spaceship from "./Spaceship";
import { useParams } from "react-router-dom";
import Settings from "./Settings";
import Menu from "./SubMenu";
import MainMenu from "./MainMenu";
import Health from "./Health";
import Timer from "./Timer";
import PageTransition from "./PageTransition";
import TagManager from "react-gtm-module";
import Scores from "./Scores";
import { useDispatch, useSelector } from "react-redux";
import { startEnergyRefillTimer } from "../actions/energyActions";
import { setGameStatus, setCurrentQuestion } from "../actions/gameActions";

const WelcomeScreen = (props) => {
  const [coins, setCoins] = useState(0);
  const [collectables, setCollectables] = useState([]);
  const [showSettings, setShowSettings] = useState(false);
  const [showGameName, setShowGameName] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [hasScore, setHasScore] = useState(false);
  const { setTheme, setFirstInteraction, playSound, theme } = props;

  const [welcomeMessage, setWelcomeMessage] = useState("");
  // const [screen, setScreen] = useState(0);
  const { screen, setScreen } = props;
  const [questions, setQuestions] = useState([]);
  const [levels, setLevels] = useState([]);
  const [menuData, setMenuData] = useState(null);
  const [appSettings, setAppSettings] = useState([]);
  const [hasnav, setHasNav] = useState(false);
  // const [isTransitioning,setIsTransitioning] = useState(false);
  const [currentLevel, setCurrentLevel] = useState(null);
  const [currentMenuId, setCurrentMenuId] = useState(null);
  const [questionIndex, setQuestionIndex] = useState(null);
  const [questionLength, setQuestionLength] = useState(null);
  let params = useParams();
  const dispatch = useDispatch();
  const { isTransitioning, setIsTransitioning } = props;

  const energy = useSelector((state) => state.user.energy);

  useEffect(() => {
    dispatch(startEnergyRefillTimer());
  }, [dispatch]);

  useEffect(() => {
    const fetchWelcomeMessage = async () => {
      let topic = 2;
      let response;
      if (params.id) {
        topic = params.id;
        response = await fetchQuiz(topic);
        // console.log(response)
        if (response) {
          setCurrentLevel(topic);
          response.title
            ? setWelcomeMessage(response.title)
            : setWelcomeMessage("");
          setLevels(response.d);
          setQuestions(response.d);
        }
      } else {
        response = await fetchMainMenu();
        if (response && response.m) {
          setMenuData(response.m);
          setLevels(response.m);
          setWelcomeMessage("For Everyone");
        }
      }
    };

    fetchWelcomeMessage();
  }, []);

  useEffect(() => {
    // console.log(levels)
    //  console.log(userData)
    setIsLoading(true);
    let name = "";
    switch (screen) {
      case 0:
        name = "titles";
        break;
      case 1:
        name = "quiz";
        break;
      case 2:
        name = "menu";
        break;
      case 3:
        name = "submenu // " + currentMenuId;
        break;
    }

    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
        pageTitle: name,
        pagePath: name,
        pageCategory: "Navigation",
      },
    });
  }, [screen]);

  const setSound = (val) => {
    // setAppSettings({'sound':val})
    //console.log(val)
    //  settingsSave(appSettings)
    // console.log(settingsGet())
  };
  const setAnimation = (val) => {};

  const onClick = (level) => {
    let levels = level.currentTarget.dataset.id;
    let selectedCategory = menuData.find((category) => category.id == levels);
    let response = selectedCategory.levels;
    setCurrentMenuId(levels);
    setLevels(response);
    setScreen(3);
    setTheme(level.currentTarget.dataset.theme);
  };

  const onStartLevel = async (level) => {
    let stage = level.currentTarget.dataset.level;

    setCurrentLevel(stage);
    const response = await fetchQuiz(stage);
    dispatch(setCurrentQuestion(0));
    if (response && response.title) setWelcomeMessage(response.title);
    setQuestions(response.d);
    setScreen(1);
  };

  const goHome = () => {
    setTheme(-1);

    localStorage.setItem("theme", -1);
    if (params.id) {
      setScreen(0);
    } else {
      setLevels(menuData);
      setScreen(2);
    }
  };

  useEffect(() => {
    window.addEventListener("beforeunload", confirmNavigation);
    return () => {
      window.removeEventListener("beforeunload", confirmNavigation);
    };
  }, []);

  const confirmNavigation = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  const handleNavigation = () => {
    const confirmed = window.confirm(
      "Are you sure you want to leave this page?"
    );

    if (confirmed) {
      window.removeEventListener("beforeunload", confirmNavigation);
      window.history.back();
    }
  };

  const nextLevel = async (scores = true) => {
    //console.log(scores);
    if (scores) {
      setHasScore(true);
    } else {
      let next;
      const foundmenu = menuData.findIndex((menu) => menu.id == currentMenuId);
      const levelindex = menuData[foundmenu].levels.findIndex(
        (level) => level.level == currentLevel
      );

      if (levelindex < menuData[foundmenu].levels.length - 1) {
        next = menuData[foundmenu].levels[levelindex + 1].level;
      } else {
        next = menuData[foundmenu].levels[0].level;
      }

      setScreen(-1);
      setCurrentLevel(next);
      const response = await fetchQuiz(next);
      if (response.title) setWelcomeMessage(response.title);
      setQuestions(response.d);
      dispatch(setCurrentQuestion(0));
      setScreen(1);
    }
  };

  return (
    <React.Fragment>
      {/* {isTransitioning && <Spaceship />} */}
      {isLoading && <PageTransition setIsLoading={setIsLoading} />}
      {hasnav && (
        <Settings
          playSound={playSound}
          appSettings={appSettings}
          setAnimation={setAnimation}
          setSound={setSound}
          setHasNav={setHasNav}
        />
      )}
      {hasScore && (
        <Scores
          setIsTransitioning={setIsTransitioning}
          currentLevel={currentLevel}
          nextLevel={nextLevel}
          goHome={goHome}
          hasScore={hasScore}
          setScreen={setScreen}
          setHasScore={setHasScore}
          playSound={playSound}
        />
      )}

      {screen == 2 && (
        <MainMenu
          playSound={playSound}
          key={`sc-${screen}`}
          onClick={onClick}
          levels={levels}
          setIsTransitioning={setIsTransitioning}
          isTransitioning={isTransitioning}
        />
      )}
      {screen == 3 && (
        <Menu
          playSound={playSound}
          key={`sb-${screen}`}
          onClick={onStartLevel}
          levels={levels}
          setIsTransitioning={setIsTransitioning}
          isTransitioning={isTransitioning}
        />
      )}

      {(screen == 1 || screen == 3) && (
        <DButton
          playSound={playSound}
          className="mobilenav circle home"
          onClick={goHome}
        ></DButton>
      )}
      {screen != 0 && (
        <DButton
          playSound={playSound}
          className="mobilesettings settings circle"
          onClick={() => setHasNav(!hasnav)}
        ></DButton>
      )}

      {(screen == 2 || screen == 3 || screen == 1) && (
        <Health energy={energy} />
      )}
      {/* {screen==1 &&  <Timer /> } */}

      {(screen == 1 || screen == 0) && (
        <div>
          {screen == 1 && !hasScore && (
            <div className="position-absolute questionindex">
              <img src="https://assets.dhvij.com/images/frames/star.svg" />
              <span>
                <em
                  style={{
                    width: `${((questionIndex + 1) * 100) / questionLength}%`,
                  }}
                ></em>
              </span>
            </div>
          )}
          <AnimatePresence>
            <div className="container">
              {/* <Spaceship /> */}

              <div className="App">
                {/* Title Screen */}
                {screen == 0 && (
                  <>
                    <motion.div
                      initial={{ opacity: 0, scale: 0 }}
                      // exit={{opacity: 0, scale:0}}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{ delay: 1, type: "spring", stiffness: 100 }}
                      className="app-title-container"
                    >
                      <img
                        className="app-title"
                        src="/app-title.svg"
                        alt="Dhviju's Skill Sheets"
                      />
                    </motion.div>

                    {!isTransitioning && (
                      <motion.div
                        key={`sc-${screen}`}
                        initial={{ opacity: 0, scale: 0 }}
                        //  exit={{opacity: 0, scale:0}}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{
                          delay: 1.4,
                          type: "spring",
                          stiffness: 100,
                        }}
                        className="text-center text-md head"
                      >
                        {welcomeMessage}
                      </motion.div>
                    )}
                    <br />
                    {welcomeMessage && (
                      <motion.div
                        initial={{ opacity: 0, scale: 0 }}
                        //  exit={{opacity: 0, scale:0}}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{
                          delay: 1.8,
                          type: "spring",
                          stiffness: 100,
                        }}
                        className="text-center"
                      >
                        <button
                          className="btn circle small mx-auto"
                          onClick={() => {
                            setFirstInteraction(true);
                            dispatch(setCurrentQuestion(0));
                            params.id ? setScreen(1) : setScreen(2);
                          }}
                        >
                          Start
                        </button>
                      </motion.div>
                    )}
                  </>
                )}
                {/* Playing Screen  */}
                {screen == 1 && !hasScore && (
                  <Quiz
                    playSound={playSound}
                    key={`sc-${screen}`}
                    setScreen={setScreen}
                    questions={questions}
                    setIsTransitioning={setIsTransitioning}
                    isTransitioning={isTransitioning}
                    currentLevel={currentLevel}
                    menuData={menuData}
                    nextLevel={nextLevel}
                    setQuestionIndex={setQuestionIndex}
                    questionIndex={questionIndex}
                    setQuestionLength={setQuestionLength}
                    theme={theme}
                  />
                )}
              </div>
            </div>
          </AnimatePresence>
        </div>
      )}
    </React.Fragment>
  );
};

export default WelcomeScreen;
