// src/reducers/sfxReducer.js

// Define the initial state
const initialState = {
    sfx: true
  };
  
  // Define the reducer function
  const sfxReducer = (state = initialState, action) => {
    switch (action.type) {
      case "TOGGLE_SFX":
        return { ...state, sfx: action.payload };
      default:
        return state;
    }
  };
  
  export default sfxReducer;
  