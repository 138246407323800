import React, { useState, useEffect } from "react";
import TagManager from "react-gtm-module";
import { useSelector, useDispatch } from "react-redux";

import { v4 as uuidv4 } from "uuid";

import App from "./components/App";
import Background from "./components/Background";
import "./App.css";
import "./ui.css";
import AudioManager from "./components/AudioManager";
import { startEnergyRefillTimer } from "./actions/energyActions";
import {
  setGameStatus,
  setCurrentLevel,
  setNextLevel,
  setSFXVolume,
  setMusicVolume,
} from "./actions/gameActions";
import Forground from "./components/Forground";

export default function AppContainer(props) {
  const [theme, setTheme] = useState(-1);
  const [firstInteraction, setFirstInteraction] = useState(false);
  const [isFocused, setIsFocused] = useState(true);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [screen, setScreen] = useState(0);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(startEnergyRefillTimer());
  }, [dispatch]);

  useEffect(() => {
    localStorage.removeItem("theme");
    localStorage.setItem("theme", theme);
  }, [theme]);
  const audioManagerRef = React.useRef(null);

  const playSound = (name) => {
    if (audioManagerRef.current) {
      audioManagerRef.current.playSound(name);
    }
  };

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "GTM-5W6QFCL",
      dataLayer: {
        userId: user.userId,
        // userProject: 'project'
      },
    };

    TagManager.initialize(tagManagerArgs);

    function handleBlur() {
      setIsFocused(false);
      // console.log('The app is now blurred.');
    }

    function handleFocus() {
      setIsFocused(true);
      // console.log('The app is now focused.');
    }

    window.addEventListener("blur", handleBlur);
    window.addEventListener("focus", handleFocus);

    return () => {
      window.removeEventListener("blur", handleBlur);
      window.removeEventListener("focus", handleFocus);
    };
  }, []);

  return (
    <React.Fragment>
      <App
        screen={screen}
        setScreen={setScreen}
        playSound={playSound}
        isFocused={isFocused}
        setTheme={setTheme}
        theme={theme}
        setFirstInteraction={setFirstInteraction}
        setIsTransitioning={setIsTransitioning}
        isTransitioning={isTransitioning}
      />
      <Background
        theme={theme}
        setTheme={setTheme}
        isFocused={isFocused}
        firstInteraction={firstInteraction}
        // isTransitioning={isTransitioning}
      />
      <AudioManager ref={audioManagerRef} isFocused={isFocused} />
    </React.Fragment>
  );
}
