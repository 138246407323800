import React, { useState, useEffect } from 'react';
import {useDispatch,useSelector} from 'react-redux'

export default function Health(props) {

    const energy = useSelector((state) => state.user.energy);


  
    return (
        <React.Fragment>
            <div className='health'>
        <img src='https://assets.dhvij.com/images/frames/energy.svg' />
        <span><em style={{width:`${energy}%`}}></em></span>
        </div>
        </React.Fragment>
    )
}
