import React, { useState, useEffect } from "react";
import DragWord from "../templates/DragWord";
import MathWithImage from "../templates/MathWithImage";
import PictureSpell from "../templates/PictureSpell";
import Connection from "../templates/Connection";
import Maths from "../templates/Maths";
import SelectImage from "../templates/SelectImage";
import FillBlanks from "../templates/FillBlanks";
import ObjectCount from "../templates/ObjectCount";
import { motion, AnimatePresence } from "framer-motion";
import ErrorBoundary from "./ErrorBoundary";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { setCurrentLevel } from "../actions/currentLevelActions";

import { reset } from "../actions/levelStatsActions";
import { useDispatch, useSelector } from "react-redux";
import {
  consumeEnergy,
  refillEnergy,
  startEnergyRefillTimer,
} from "../actions/energyActions";
import { setGameStatus, setCurrentQuestion } from "../actions/gameActions";

import Heading from "./Heading";
import DragArrange from "../templates/DragArrange";

const Quiz = React.memo((props) => {
  const [currentSection, setCurrentSection] = useState(0);
  const currentQuestion = useSelector((state) => state.game.currentQuestion);

  //  const [parent, enableAnimations] = useAutoAnimate(/* optional config */)
  const {
    currentLevel,

    nextLevel,
    setScreen,
    questionIndex,
    setQuestionIndex,
    setQuestionLength,
    theme,
  } = props;
  const { isTransitioning, setIsTransitioning } = props;
  const questions = props.questions;
  const dispatch = useDispatch();

  const energy = useSelector((state) => state.user.energy);

  const handleConsumeEnergy = (amount) => {
    const remainingEnergy = dispatch(consumeEnergy(amount));
    // console.log("Remaining energy:", remainingEnergy);
  };

  useEffect(() => {
    dispatch(setCurrentLevel(currentLevel));
    dispatch(reset());
  }, [currentLevel]);

  useEffect(() => {
    handleConsumeEnergy(5);
  }, [currentSection]);

  return (
    <>
      {questions &&
        questions.map((i, count) => {
          if (count == currentQuestion) {
            console.log(i.__type);
            console.log(props.questionIndex);
            if ("math_with_image" == i.__type && i.math) {
              return (
                <AnimatePresence key={count}>
                  <motion.div className="container d-flex flex-column align-items-center justify-content-between h-100 w-100">
                    <Heading i={i} props={props} />

                    <MathWithImage
                      data={i.math}
                      props={props}
                      totalSections={questions.length}
                      currentSection={currentSection}
                      setCurrentSection={setCurrentSection}
                    />
                  </motion.div>
                </AnimatePresence>
              );
            }

            if ("picture_spell" == i.__type && i.spelling) {
              return (
                <AnimatePresence key={count}>
                  <motion.div className="container d-flex flex-column align-items-center justify-content-between h-100 w-100">
                    <Heading i={i} props={props} />

                    <PictureSpell
                      data={i.spelling}
                      props={props}
                      totalSections={questions.length}
                      currentSection={currentSection}
                      setCurrentSection={setCurrentSection}
                    />
                  </motion.div>
                </AnimatePresence>
              );
            }

            if ("drag_word" == i.__type && i.images) {
              return (
                <AnimatePresence key={count}>
                  <motion.div className="container d-flex flex-column align-items-center justify-content-between h-100 w-100">
                    <Heading i={i} props={props} />

                    <DragWord
                      key={count}
                      data={i.images}
                      correct={i.images}
                      props={props}
                      totalSections={questions.length}
                      currentSection={currentSection}
                      setCurrentSection={setCurrentSection}
                    />
                  </motion.div>
                </AnimatePresence>
              );
            }

            if ("connection" == i.__type && i.left_side && i.right_side) {
              return (
                <AnimatePresence key={count}>
                  <motion.div className="container d-flex flex-column align-items-center justify-content-between h-100 w-100">
                    <Heading i={i} props={props} />
                    <ErrorBoundary>
                      <Connection
                        key={count}
                        data={[i.left_side, i.right_side]}
                        correct={[i.left_side, i.right_side]}
                        props={props}
                        nextLevel={nextLevel}
                        totalSections={questions.length}
                        currentSection={currentSection}
                        setCurrentSection={setCurrentSection}
                      />
                    </ErrorBoundary>
                  </motion.div>
                </AnimatePresence>
              );
            }

            if ("math" == i.__type && i.math) {
              return (
                <AnimatePresence key={count}>
                  <motion.div className="container d-flex flex-column align-items-center justify-content-between h-100 w-100">
                    <Heading i={i} props={props} />

                    <Maths
                      key={count}
                      data={i.math}
                      props={props}
                      totalSections={questions.length}
                      currentSection={currentSection}
                      setCurrentSection={setCurrentSection}
                    />
                  </motion.div>
                </AnimatePresence>
              );
            }

            if ("select_image" == i.__type && i.images) {
              return (
                <SelectImage
                  key={count}
                  data={i.images}
                  props={props}
                  nextLevel={nextLevel}
                  setScreen={setScreen}
                  totalSections={questions.length}
                  currentSection={currentSection}
                  setCurrentSection={setCurrentSection}
                />
              );
            }

            if ("fill_blanks" == i.__type && i.words) {
              return (
                <AnimatePresence key={count}>
                  <motion.div className="container d-flex flex-column align-items-center justify-content-between h-100 w-100">
                    <Heading i={i} props={props} />

                    <FillBlanks
                      key={count}
                      data={i.words}
                      props={props}
                      totalSections={questions.length}
                      currentSection={currentSection}
                      setCurrentSection={setCurrentSection}
                    />
                  </motion.div>
                </AnimatePresence>
              );
            }
            if ("object_count" == i.__type && i.counter) {
              return (
                <AnimatePresence key={count}>
                  <motion.div className="container d-flex flex-column align-items-center justify-content-between h-100 w-100">
                    <Heading i={i} props={props} />

                    <ObjectCount
                      key={`count${count}`}
                      data={i.counter}
                      props={props}
                      totalSections={questions.length}
                      currentSection={currentSection}
                      setCurrentSection={setCurrentSection}
                    />
                  </motion.div>
                </AnimatePresence>
              );
            }

            if ("drag_arrange_images" == i.__type) {
              return (
                <DragArrange
                  key={count}
                  data={i.layers}
                  stage_images={i.stage_images}
                  props={props}
                  totalSections={questions.length}
                  currentSection={currentSection}
                  setCurrentSection={setCurrentSection}
                  theme={theme}
                  setScreen={setScreen}
                />
              );
            }

            // return null;
          }
        })}
      <div className="d-flex flex-row justify-content-start pages">
        <div
          className="btn btn-primary circle prev"
          area-label="prev"
          onClick={() => setScreen(3)}
        ></div>
      </div>
    </>
  );
});
export default Quiz;
