import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { slugify } from "../utils/Utils";
import { Howl, Howler } from "howler";
import { useSelector, useDispatch } from "react-redux";
import { updateAttempts } from "../actions/levelStatsActions";

export default function Maths(props) {
  const questions = props.data;

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answer, setAnswer] = useState([]);
  const {
    isTransitioning,
    setIsTransitioning,
    setScreen,
    nextLevel,
    setQuestionIndex,
    setQuestionLength,
  } = props.props;
  const [inputAnswer, setInputAnswer] = useState([]);
  const [correctAnswer, setCorrectAnswer] = useState();
  const { sfx } = useSelector((state) => state.sfx);
  const dispatch = useDispatch();

  useEffect(() => {
    setQuestionIndex(currentQuestion);
    setQuestionLength(questions.length);
  }, []);

  useEffect(() => {
    let answerCount = Number(
      eval(
        `${questions[currentQuestion].value_1} ${questions[currentQuestion].operator} ${questions[currentQuestion].value_2}`
      )
    );

    setCorrectAnswer(answerCount);

    let allAnswers = [];
    const exclude = new Set();
    exclude.add(answerCount); // Exclude answerCount

    while (exclude.size < 4) {
      let randomNumber = getRandomInt(1, answerCount + 10, exclude);
      exclude.add(randomNumber);
    }

    allAnswers = Array.from(exclude); // Convert set to array
    allAnswers.sort(() => Math.random() - 0.5);
    setAnswer(allAnswers);

    if (!sfx) return;

    const number = `${questions[currentQuestion].value_1}${questions[currentQuestion].operator}${questions[currentQuestion].value_2}`;
    const mp3 = `https://assets.dhvij.com/sounds/math/${slugify(number)}.mp3`;
    const sound = new Howl({
      src: [mp3],
    });
    sound.play();
  }, [currentQuestion, questions, sfx]);

  const getRandomInt = (min, max, exclude) => {
    min = Math.ceil(min);
    max = Math.floor(max);

    let randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;

    while (exclude.has(randomNumber)) {
      randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    }

    return randomNumber;
  };

  const toggleSelection = (e) => {
    e.target.classList.toggle("active");
    let key = e.target.dataset.key;
    let savedanswer = inputAnswer;
    if (!e.target.classList.contains("active")) {
      var index = savedanswer.indexOf(key);
      if (index !== -1) {
        savedanswer.splice(index, 1);
      }
    } else {
      savedanswer.push(key);
    }

    setInputAnswer(savedanswer);
    return handleAnswer(currentQuestion + 1) === false
      ? setTimeout(() => {
          e.target.classList.remove("active");
          setInputAnswer([]);
        }, 100)
      : null;
  };

  const handleAnswer = (index) => {
    let correct = false;
    if (
      JSON.stringify(inputAnswer.sort()) ===
      JSON.stringify([correctAnswer.toString()].sort())
    ) {
      correct = true;
    }
    if (correct) {
      correct = false;
      // leaveQuestion()
      props.props.playSound("correct");
      if (sfx) props.props.playSound("win");
      // setCurrentQuestion(currentQuestion + 1)
      setIsTransitioning(true);
      setTimeout(() => {
        setQuestionIndex(currentQuestion + 1);
        setQuestionLength(questions.length);

        setIsTransitioning(false);
        if (index <= questions.length - 1) {
          setCurrentQuestion(index);
          setInputAnswer([]);
        } else {
          nextLevel();
        }
      }, 2000);
      return true;
    } else {
      props.props.playSound("wrong");
      dispatch(updateAttempts());
      return false;
    }
  };

  return (
    <>
      {questions.map((j, count) => {
        return (
          <>
            {count == currentQuestion && (
              <>
                {!isTransitioning && (
                  <motion.div
                    key={count}
                    className=" d-flex flex-column flex-md-row justify-content-between w-100"
                    initial={{ opacity: 0, scale: 0 }}
                    exit={{ opacity: 0, scale: 0 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ delay: 0, type: "spring", stiffness: 100 }}
                  >
                    <div className="d-flex flex-row justify-content-between w-100">
                      <div className="text-center thebox">
                        <div className="value">{j.value_1}</div>
                      </div>
                      <div className="operator text-center d-flex justify-content-center align-items-center">
                        {j.operator}
                      </div>
                      <div className="text-center thebox">
                        <div className="value">{j.value_2}</div>
                      </div>
                    </div>
                  </motion.div>
                )}
                <div className="text-center "></div>
                <div className="dh-grid v2 mt-2 w-100">
                  {answer.map((j, count) => {
                    return (
                      !isTransitioning && (
                        <motion.div
                          initial={{ opacity: 0, scale: 0 }}
                          exit={{ opacity: 0, scale: 0 }}
                          animate={{ opacity: 1, scale: 1 }}
                          transition={{
                            delay: 1 + 0.4 * count,
                            type: "spring",
                            stiffness: 100,
                          }}
                          key={`k${count}`}
                          className=" item"
                          onClick={(e) => {
                            toggleSelection(e);
                          }}
                          data-key={j}
                        >
                          <div className="text-center d-flex flex-column pe-none">
                            <div className="text-center thebox  drag">
                              <div className="text-center number">{j}</div>
                            </div>
                          </div>
                        </motion.div>
                      )
                    );
                  })}
                </div>
              </>
            )}
          </>
        );
      })}
    </>
  );
}
