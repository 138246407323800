/**
 *
 * @param {*} places
 * @returns number
 */
import axios from "axios";
import { Howl, Howler } from "howler";

let cancelTokenSource;

export const makeApiRequest = async (endpoint, method = "GET", data = null) => {
  // Cancel any previous requests
  if (cancelTokenSource) {
    cancelTokenSource.cancel();
  }

  // Create a new cancel token source for the current request
  cancelTokenSource = axios.CancelToken.source();

  try {
    const today = new Date();
    const day = today.getDate();

    let sandbox = "?d=" + day;
    // let sandbox = ""
    // let mode = "assets"
    let mode = "data";
    if (window.location.href.includes("localhost")) {
      sandbox = "?sandbox=1&d=" + day;
      mode = "data";
    }

    const response = await axios({
      method: method,
      url: `https://${mode}.dhvij.com/wp-json/quiz/v1${endpoint}${sandbox}`,
      // url: `https://data.dhvij.com/wp-json/quiz/v1${endpoint}?sandbox=1`,
      data: data,
      cancelToken: cancelTokenSource.token,
    });

    return response.data;
  } catch (error) {
    if (axios.isCancel(error)) {
    } else {
      console.error(error);
    }
  }
};

export const zeroPad = (places) => {
  const min = 1;
  const max = 15;
  let num = Math.floor(Math.random() * (max - min) + min);
  var zero = places - num.toString().length + 1;
  return Array(+(zero > 0 && zero)).join("0") + num;
};

// export const winmp3 = new Audio();

export async function fetchMainMenu() {
  return await makeApiRequest("/menu");
  // return data

  // request
  // const response = await fetch('https://data.dhvij.com/wp-json/quiz/v1/menu');
  // const data = await response.json();
  // return data;
}

//   export async function fetchLevels(level) {
//     const response = await fetch(`https://data.dhvij.com/wp-json/quiz/v1/levels/${level}`);
//     const data = await response.json();
//     return data;
//   }

export async function fetchQuiz(id) {
  return await makeApiRequest(`/quiz/${id}`);

  // const response = await fetch(`https://data.dhvij.com/wp-json/quiz/v1/quiz/${id}`);
  // // const response = await fetch(`https://data.dhvij.com/wp-json/quiz/v1/quiz/${id}?sandbox`);
  // const data = await response.json();
  // return data;
}

export async function fetchCollectables() {
  return await makeApiRequest("/collections");

  // const response = await fetch('https://data.dhvij.com/wp-json/quiz/v1/collections');
  // const data = await response.json();
  // return data;
}

export const settingsManager = {
  get: (key) => {
    const value = localStorage.getItem(key);
    if (value === null) {
      return null;
    }
    try {
      return JSON.parse(value);
    } catch (e) {
      console.error(`Failed to parse settings value for key '${key}': ${e}`);
      return null;
    }
  },
  set: (key, value) => {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      console.error(`Failed to set settings value for key '${key}': ${e}`);
    }
  },
};

export const slugify = (text) => {
  return text
    .toString()
    .toLowerCase()
    .trim()
    .replace(/\s+/g, "-") // Replace spaces with hyphens
    .replace(/[^\w\-\+]+/g, "") // Remove all non-word characters except hyphens and plus signs
    .replace(/\-\-+/g, "-") // Replace multiple consecutive hyphens with a single hyphen
    .replace(/^-+/, "") // Remove leading hyphens
    .replace(/-+$/, ""); // Remove trailing hyphens
};

export const checkFileExists = async (fileUrl) => {
  return fetch(fileUrl, {
    method: "HEAD",
  })
    .then((response) => {
      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    })
    .catch(() => {
      return false;
    });
};
