var themes = {
  "-1": {
    3: "1.jpg",
    4: "2.png",
    5: "3.png",
    6: "4.png",
    7: "5.png",
  },
  0: {
    3: "1.png",
    4: "2.png",
    5: "3.png",
    6: "4.png",
    7: "5.png",
    8: "6.png",
    9: "7.png",
    10: "8.png",
    11: "9.png",
    12: "10.png",
    13: "11.png",
  },
  1: {
    3: "1.png",
    4: "2.png",
    // 5: "3.png",
    6: "4.png",
    // 7: "5.png",
  },
  2: {
    2: "1.png",
    3: "2.png",
    4: "3.png",
    // 5: "4.png",
    // 6: "5.png",
    // 7: "6.png",
    8: "7.png",
    // 9: "8.png",
  },
  3: {
    2: "1.png",
    // 3: "2.png",
    // 4: "3.png",
    5: "4.png",
    6: "5.png",
  },
  4: {
    2: "1.png",
    3: "2.png",
    4: "3.png",
    // 5: "4.png",
    // 6: "5.png",
    7: "6.png",
  },
  5: {
    2: "1.png",
    3: "2.png",
    // 4: "3.png",
    5: "4.png",
    6: "5.png",
    // 7: "6.png",
  },
  6: {
    2: "1.png",
    3: "2.png",
    // 4: "3.png",
    // 5: "4.png",
    6: "5.png",
    // 7: "6.png",
  },
  7: {
    2: "1.png",
    3: "2.png",
    // 4: "3.png",
    // 5: "4.png",
    6: "5.png",
  },
  8: {
    2: "1.png",
    3: "2.png",
    // 4: "3.png",
    // 5: "4.png",
    // 6: "5.png",
    7: "6.png",
    8: "7.png",
    9: "8.png",
    10: "9.png",
  },
  9: {
    2: "1.png",
    3: "2.png",
    4: "3.png",
    5: "4.png",
    // 6: "5.png",
  },
  10: {
    2: "1.png",
    3: "2.png",
    4: "3.png",
    5: "4.png",
    // 6: "5.png",
    // 7: "6.png",
  },
  11: {
    2: "1.png",
    3: "2.png",
    4: "3.png",
    // 5: "4.png",
    6: "5.png",
  },
  12: {
    2: "1.png",
    // 3: "2.png",
    4: "3.png",
    // 5: "4.png",
    // 6: "5.png",
    // 7: "6.png",
    8: "7.png",
  },
  13: {
    2: "1.png",
    3: "2.png",
    4: "3.png",
    5: "4.png",
    // 6: "5.png",
    7: "6.png",
  },
  14: {
    2: "1.png",
    // 3: "2.png",
    // 4: "3.png",
    // 5: "4.png",
    6: "5.png",
    7: "6.png",
  },
  15: {
    2: "1.png",
    3: "2.png",
    4: "3.png",
    // 5: "4.png",
    6: "5.png",
  },
  16: {
    2: "1.png",
    // 3: "2.png",
    // 4: "3.png",
    5: "4.png",
    // 6: "5.png",
    // 7: "6.png",
    8: "7.png",
  },
};

export default themes;
