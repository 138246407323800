import { v4 as uuidv4 } from 'uuid';
import { UPDATE_LEVEL, RETRIEVE_LEVEL,CONSUME_ENERGY, REFILL_ENERGY } from '../actions/userActions';

const initialState = {
  userId: uuidv4(),
  levels: {},
  energy: 100,
  lastRefillTime: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_LEVEL:
      return {
        ...state,
        levels: {
          ...state.levels,
          [action.levelId]: {
            ...state.levels[action.levelId],
            ...action.payload,
          },
        },
      };
    case RETRIEVE_LEVEL:
      return {
        ...state,
        levels: {
          ...state.levels,
          [action.levelId]: state.levels[action.levelId],
        },
      };
    
      case CONSUME_ENERGY:
        return {
          ...state,
          energy: Math.max(state.energy - action.payload, 0),
        };
      case REFILL_ENERGY:
        return {
          ...state,
          energy: 100,
        };
    default:
      return state;
  }
};

export default userReducer;
