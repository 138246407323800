import { motion, AnimatePresence } from "framer-motion";
import React, { useState, useEffect } from "react";

export default function MathWithImage(props) {
  const math = props.data;
  const { isTransitioning, setIsTransitioning } = props.props;
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [inputAnswer, setInputAnswer] = useState([]);
  const [correctAnswer, setCorrectAnswer] = useState();

  useEffect(() => {
    //sets the current question
    setupQuestion(currentQuestion);
  }, [currentQuestion]);

  const setupQuestion = (question) => {
    // gets a question and sets answers to be checked at a later stage
    // console.log(math[question].value_1);

    let answer = 0;
    let v1 = math[question].value_1.name;
    let v2 = math[question].value_2.name;
    let opr = math[question].operator;
    if ("+" == opr) answer = parseFloat(v1) + parseFloat(v2);
    if ("-" == opr) answer = parseFloat(v1) - parseFloat(v2);
    if ("*" == opr) answer = parseFloat(v1) * parseFloat(v2);
    if ("/" == opr) answer = parseFloat(v1) / parseFloat(v2);
    setCorrectAnswer(answer);

    // setInputAnswer(blankAnswer);
  };

  const handleAnswer = (index) => {
    let correct = true;
    if (inputAnswer != correctAnswer) correct = false;

    // setIsTransitioning(()=>true);
    if (correct) {
      props.props.playSound("correct");
      setIsTransitioning(() => true);
      setTimeout(() => {
        setIsTransitioning(false);
        setCurrentQuestion(index);
        setInputAnswer([]);
      }, 2000);
    } else {
      props.props.playSound("wrong");
    }
  };

  const handelInvalid = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const regex = /^[0-9]*$/; // only allow numbers

    if (!regex.test(keyValue)) {
      event.preventDefault();
    }
  };

  const handleInputChange = (event) => {
    const regex = /^[0-9]*$/; // regular expression to match alphanumeric characters only
    const inputValue = event.target.value;

    if (inputValue === "" || regex.test(inputValue)) {
      // console.log(event.target.value)
      setInputAnswer(inputValue);
      // console.log(inputValue)
    }

    // const inputIndex = event.target.getAttribute("data-key");
    //
  };

  return math.map((j, count) => {
    if (count === currentQuestion) {
      return (
        <AnimatePresence key={count}>
          {!isTransitioning && (
            <motion.div
              exit={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: 1, type: "spring", stiffness: 100 }}
              key={j}
              className="mathwithimage d-lg-flex flex-row justify-content-between w-100"
            >
              <div className="d-md-flex flex-row justify-content-between">
                <motion.div
                  initial={{ opacity: 0, scale: 0 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ delay: 0, type: "spring", stiffness: 100 }}
                  className="text-center thebox"
                >
                  <div className="value">{j.value_1.name}</div>
                  {[...Array(Number(j.value_1.name))].map((e, index) => (
                    <img
                      key={index}
                      src={j.value_1.image}
                      onClick={(e) => e.target.classList.toggle("active")}
                    />
                  ))}
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, scale: 0 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ delay: 0.5, type: "spring", stiffness: 100 }}
                  className="operator text-center d-flex justify-content-center align-items-center"
                >
                  {j.operator}
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, scale: 0 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ delay: 1, type: "spring", stiffness: 100 }}
                  className="text-center thebox"
                >
                  <div className="value">{j.value_2.name}</div>
                  {[...Array(Number(j.value_2.name))].map((e, index) => (
                    <img
                      key={index}
                      src={j.value_2.image}
                      onClick={(e) => e.target.classList.toggle("active")}
                    />
                  ))}
                </motion.div>
              </div>
              <motion.div
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: 1.5, type: "spring", stiffness: 100 }}
                className="operator text-center d-flex justify-content-center align-items-center"
              >
                =
              </motion.div>
              <motion.div
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: 2, type: "spring", stiffness: 100 }}
                className="text-center thebox"
              >
                {!j.total.name && (
                  <input
                    pattern="[0-9]*"
                    maxLength={2}
                    inputMode="numeric"
                    onKeyPress={(e) => handelInvalid(e)}
                    onKeyUp={(e) => handleInputChange(e)}
                  />
                )}

                {/* <img src={j.total.image}/> */}
              </motion.div>
            </motion.div>
          )}
          <motion.div
            initial={{ opacity: 0, visibility: "hidden" }}
            animate={{ opacity: 1, visibility: "visible" }}
            transition={{
              delay: 2 + count * 0.3,
              type: "tween",
              duration: 0.4,
            }}
            className="d-flex flex-row justify-content-between pages"
          >
            {currentQuestion !== 0 ? (
              <div
                className="btn btn-primary circle prev"
                area-label="Previous"
                onClick={() => setCurrentQuestion(currentQuestion - 1)}
              ></div>
            ) : (
              <div></div>
            )}
            {currentQuestion !== math.length - 1 && (
              <div
                className="btn btn-primary circle next"
                area-label="Next"
                onClick={() => handleAnswer(currentQuestion + 1)}
              ></div>
            )}

            {currentQuestion === math.length - 1 &&
              props.currentSection < props.totalSections - 1 && (
                <div
                  className="btn btn-primary circle next"
                  area-label="Next Chapter"
                  onClick={() => {
                    props.setCurrentSection(props.currentSection + 1);
                  }}
                ></div>
              )}
          </motion.div>
        </AnimatePresence>
      );
    }
  });
}
