// gameActions.js

export const setGameStatus = (status) => ({
  type: "SET_GAME_STATUS",
  payload: status,
});

export const setCurrentPage = (page) => ({
  type: "SET_CURRENT_PAGE",
  payload: page,
});

export const setCurrentQuestion = (question) => {
  return (dispatch) => {
    // Dispatch setGameStatus as transitioning
    // dispatch(setGameStatus("transitioning"));

    // // Wait for 4 seconds
    // setTimeout(() => {
    //   // Dispatch setGameStatus back to playing
    //   dispatch(setGameStatus("playing"));
    // }, 4000); // 4 seconds in milliseconds

    // Dispatch setCurrentQuestion with the new question
    dispatch({
      type: "SET_CURRENT_QUESTION",
      payload: question,
    });
  };
};

// export const setCurrentQuestion = (question) => ({
//   type: "SET_CURRENT_QUESTION",
//   payload: question,
// });

export const setNextQuestion = (question) => ({
  type: "SET_NEXT_QUESTION",
  payload: question,
});

export const setCurrentLevel = (level) => ({
  type: "SET_CURRENT_LEVEL",
  payload: level,
});

export const setNextLevel = (level) => ({
  type: "SET_NEXT_LEVEL",
  payload: level,
});

export const setQuestionType = (questionType) => ({
  type: "SET_QUESTION_TYPE",
  payload: questionType,
});

export const setCurrentTheme = (theme) => ({
  type: "SET_CURRENT_THEME",
  payload: theme,
});

export const setSFXStatus = (status) => ({
  type: "SET_SFX_STATUS",
  payload: status,
});

export const setSFXVolume = (volume) => ({
  type: "SET_SFX_VOLUME",
  payload: volume,
});

export const setMusicStatus = (status) => ({
  type: "SET_MUSIC_STATUS",
  payload: status,
});

export const setMusicVolume = (volume) => ({
  type: "SET_MUSIC_VOLUME",
  payload: volume,
});

export const setTransitioning = (status) => ({
  type: "SET_TRANSITIONING",
  payload: status,
});
