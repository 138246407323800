import React, { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { slugify } from "../utils/Utils";
import { Howl, Howler } from "howler";
import { useSelector, useDispatch } from "react-redux";
import { updateAttempts } from "../actions/levelStatsActions";

export default function ObjectCount(props) {
  const questions = props.data;

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answer, setAnswer] = useState([]);
  const {
    isTransitioning,
    setIsTransitioning,
    setScreen,
    nextLevel,
    setQuestionIndex,
    setQuestionLength,
  } = props.props;
  const [inputAnswer, setInputAnswer] = useState([]);
  const [correctAnswer, setCorrectAnswer] = useState();
  const [activeItems, setActiveItems] = useState([]);
  const { currentSection } = props;
  const { sfx } = useSelector((state) => state.sfx);
  const levelStats = useSelector((state) => state.levelStats);
  const dispatch = useDispatch();

  useEffect(() => {
    setQuestionIndex(currentQuestion);
    setQuestionLength(questions.length);
  }, []);

  useEffect(() => {
    let answerCount = Number(questions[currentQuestion].object.name);
    setCorrectAnswer(answerCount);

    let allAnswers = [];
    const exclude = new Set();
    exclude.add(answerCount); // Exclude answerCount

    while (exclude.size < 4) {
      let randomNumber = getRandomInt(1, answerCount + 10, exclude);
      exclude.add(randomNumber);
    }

    allAnswers = Array.from(exclude); // Convert set to array
    allAnswers.sort(() => Math.random() - 0.5);
    setAnswer(allAnswers);

    setActiveItems([]);
  }, [questions, currentQuestion, setAnswer]);

  const getRandomInt = (min, max, exclude) => {
    min = Math.ceil(min);
    max = Math.floor(max);

    let randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;

    while (exclude.has(randomNumber)) {
      randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    }

    return randomNumber;
  };

  const toggleSelection = (e) => {
    e.target.classList.toggle("active");
    let key = e.target.dataset.key;
    let savedanswer = inputAnswer;
    if (!e.target.classList.contains("active")) {
      var index = savedanswer.indexOf(key);
      if (index !== -1) {
        savedanswer.splice(index, 1);
      }
    } else {
      savedanswer.push(key);
    }

    setInputAnswer(savedanswer);

    return handleAnswer(currentQuestion + 1) === false
      ? setTimeout(() => {
          e.target.classList.remove("active");
          setInputAnswer([]);
        }, 100)
      : null;
  };

  const handleAnswer = (index) => {
    let correct = false;
    if (
      JSON.stringify(inputAnswer.sort()) ===
      JSON.stringify([correctAnswer.toString()].sort())
    ) {
      correct = true;
    } else {
      props.props.playSound("wrong");
      return false;
    }
    if (correct) {
      correct = false;
      // leaveQuestion()
      props.props.playSound("correct");
      if (sfx) props.props.playSound("win");
      // props.setCurrentSection(props.currentSection + 1)
      setIsTransitioning(true);
      setTimeout(() => {
        setQuestionIndex(currentQuestion + 1);
        setQuestionLength(questions.length);

        setIsTransitioning(false);
        if (currentQuestion < questions.length - 1) {
          setCurrentQuestion(index);
          setInputAnswer([]);
        } else {
          nextLevel();
        }
      }, 2000);
      return true;
    } else {
      props.props.playSound("wrong");
      dispatch(updateAttempts());
      return false;
    }
  };

  const setHighlight = (index) => {
    if (activeItems.includes(index)) {
      setActiveItems(activeItems.filter((item) => item !== index));
    } else {
      setActiveItems([...activeItems, index]);
    }
  };

  useEffect(() => {
    if (!sfx) return;
    const number = activeItems.length;
    if (number != 0) {
      const mp3 =
        "https://assets.dhvij.com/sounds/numbers/" + slugify(number) + ".mp3";
      var sound = new Howl({
        src: [mp3],
      });
      sound.play();
    }
  }, [activeItems]);

  // console.log(props.totalSections)

  return questions.map((j, count) => {
    return (
      count == currentQuestion && (
        <React.Fragment>
          <div
            key={`p${count}`}
            className=" d-flex flex-column    justify-content-between w-100"
          >
            <AnimatePresence>
              {!isTransitioning && (
                <motion.div
                  key={`container`}
                  initial={{ x: "100vw" }}
                  animate={{ x: "0px" }}
                  exit={{ x: "-100vw" }}
                  transition={{
                    delay: 0,
                    duration: 2,
                    ease: "linear",

                    // type: "normal",
                    stiffness: 0,
                  }}
                  className="text-center text-md  d-flex flex-column align-items-center justify-content-center"
                >
                  <div className="d-flex flex-row justify-content-between">
                    <div className="text-center thebox w-100 d-flex flex-wrap">
                      {[...Array(Number(j.object.name))].map((e, index) => (
                        <motion.div
                          key={`i${index}`}
                          initial={{ opacity: 0, scale: 0 }}
                          exit={{ opacity: 0, scale: 0 }}
                          animate={{ opacity: 1, scale: 1 }}
                          transition={{
                            delay: 1 + 0.4 * index,
                            type: "spring",
                            stiffness: 100,
                          }}
                        >
                          <img
                            key={e}
                            className="countimage"
                            src={j.object.image}
                            onClick={(e) => {
                              e.target.classList.toggle("active");
                              setHighlight(index);
                            }}
                          />
                        </motion.div>
                      ))}
                    </div>
                  </div>
                  <div className="dh-grid v2 mt-2">
                    {answer.map((j, count) => {
                      return (
                        !isTransitioning && (
                          <motion.div
                            initial={{ opacity: 0, scale: 0 }}
                            exit={{ opacity: 0, scale: 0 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{
                              delay: 1 + 0.4 * count,
                              type: "spring",
                              stiffness: 100,
                            }}
                            key={`p${count}`}
                            className=" item"
                            onClick={(e) => {
                              toggleSelection(e);
                            }}
                            data-key={j}
                          >
                            <div className="text-center d-flex flex-column pe-none">
                              <div className="text-center thebox  drag">
                                <div className="text-center number">{j}</div>
                              </div>
                            </div>
                          </motion.div>
                        )
                      );
                    })}
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </React.Fragment>
      )
    );
  });
}
