import {
    UPDATE_ATTEMPTS,
    UPDATE_TIME,
    UPDATE_TOTAL,
    RESET,
  } from '../actions/levelStatsActions';
  
  const initialState = {
    attempts: 0,
    time: 0,
    total: 0,
    rating: 3,
  };
  
  const levelStatsReducer = (state = initialState, action) => {
    switch (action.type) {
      case UPDATE_ATTEMPTS:
        return {
          ...state,
          attempts: state.attempts + 1,
          rating: calculateRating(state.attempts + 1),
        };
      case UPDATE_TIME:
        return {
          ...state,
          time: action.payload,
        };
      case UPDATE_TOTAL:
        return {
          ...state,
          total: action.payload,
        };
      case RESET:
        return {
          attempts: 0,
          time: 0,
          total: 0,
          rating: 3,
        };
      default:
        return state;
    }
  };
  
  const calculateRating = (attempts) => {

    if (attempts <= 2) {
      return 3;
    } else if (attempts <= 6) {
      return 2;
    } else {
      return 1;
    }
  };
  
  export default levelStatsReducer;
  