// Action Types
export const UPDATE_LEVEL = 'UPDATE_LEVEL';
export const RETRIEVE_LEVEL = 'RETRIEVE_LEVEL';

export const CONSUME_ENERGY = 'CONSUME_ENERGY';
export const REFILL_ENERGY = 'REFILL_ENERGY';

// Action Creators
export const updateLevel = (levelId, payload) => {
  return {
    type: UPDATE_LEVEL,
    levelId,
    payload,
  };
};

export const retrieveLevel = (levelId) => {
  return {
    type: RETRIEVE_LEVEL,
    levelId,
  };
};


export const consumeEnergy = (amount) => ({
  type: CONSUME_ENERGY,
  payload: amount,
});

export const refillEnergy = () => ({
  type: REFILL_ENERGY,
});

