import React, { useState, useEffect, useRef } from "react";
import { DndContext, DragOverlay } from "@dnd-kit/core";
import { Droppable } from "../components/Droppable";
import { Draggable } from "../components/Draggable";
import Tiles from "../components/Tiles";
import { slugify } from "../utils/Utils";
import { motion, AnimatePresence } from "framer-motion";
// import AudioPlayer from "./AudioPlayer";
import { Howl, Howler } from "howler";
import TagManager from "react-gtm-module";
import { useSelector, useDispatch } from "react-redux";
import { updateAttempts } from "../actions/levelStatsActions";
import {
  setGameStatus,
  setCurrentLevel,
  setNextLevel,
  setSFXVolume,
  setCurrentQuestion,
  setMusicVolume,
} from "../actions/gameActions";

export default function DragArrange(props) {
  // const [currentQuestion, setCurrentQuestion] = useState(
  //   props.props.currentQuestion ? props.props.currentQuestion : 0
  // );

  const [inputAnswer, setInputAnswer] = useState();
  const [correctAnswer, setCorrectAnswer] = useState();
  const {
    setScreen,
    nextLevel,
    currentLevel,
    setQuestionIndex,
    setQuestionLength,
    theme,
  } = props.props;
  const [isTransitioning, setIsTransitioning] = useState();

  const [audio, setAudio] = useState(null);
  // const [isAudioLoaded, setIsAudioLoaded] = useState(false);
  const [audioError, setAudioError] = useState(false);
  const [sound, setSound] = useState(null);
  const { sfx } = useSelector((state) => state.sfx);
  const levelStats = useSelector((state) => state.levelStats);

  const gameSettings = useSelector((state) => state.game);
  const gameStatus = useSelector((state) => state.game.gameStatus);
  const currentQuestion = useSelector((state) => state.game.currentQuestion);

  const dispatch = useDispatch();

  const stage = useRef(null);

  let questions = props.props.questions[currentQuestion];
  let mode = questions["mode"];
  let map = questions["map"]
    ? questions["map"]
        .toString()
        .substring(0, questions["map"].toString().indexOf(" "))
        .trim() - 1
    : 1;

  // let stagedata = questions["stage_images"];
  // let stagedata = questions["stage"] ? JSON.parse(questions["stage"]) : [];

  // useEffect(() => {
  //   // This code will run whenever `isTransitioning` changes
  //   console.log("isTransitioning changed:", isTransitioning);
  // }, [isTransitioning]);

  const playAnimation = (time = 2010) => {
    dispatch(setGameStatus("transitioning"));
    setTimeout(() => dispatch(setGameStatus("playing")), time);
  };
  const playSound = () => {
    sound.stop();
    sound.play();
  };

  useEffect(() => {
    playAnimation(2010);
    console.log(currentQuestion);
  }, []);
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
        pageTitle: questions.instruction,
        pagePath: currentLevel,
        pageCategory: "Question",
      },
    });
    // playAnimation();
    setupQuestion(currentQuestion);
    // enterQuestion();
    setQuestionIndex(currentQuestion);
    setQuestionLength(props.props.questions.length);

    setAudioError(false);

    const mp3 =
      "https://assets.dhvij.com/sounds/" +
      currentLevel +
      "/" +
      slugify(questions.instruction) +
      ".mp3";

    // const status =  checkFileExists(mp3)

    setAudio(mp3);
    setSound(
      new Howl({
        src: [mp3],
      })
    );
    var sound = new Howl({
      src: [mp3],
      onloaderror: () => setAudioError(true),
      onload: () => setAudioError(false),
    });
    if (sfx) {
      setTimeout(function () {
        sound.play();
      }, 1500);
    }
  }, [currentQuestion]);

  const setupQuestion = (question) => {
    // gets a question and sets answers to be checked at a later stage
    // console.log(props.props.questions[currentQuestion]);
    let answer = props.props.questions[currentQuestion].correct_answer
      .toString()
      .split(",");
    setCorrectAnswer(answer);
    setInputAnswer([]);
    setDropped(() => questions.layers.map(() => false));
    setChecked(() => questions.layers.map(() => false));
  };

  const handleAnswer = (index) => {
    let correct = false;
    // console.log(JSON.stringify(inputAnswer.sort()) ,'==', JSON.stringify(correctAnswer.sort()) , '==' ,index)
    // if (!correctAnswer) return true;

    if (questions.mode == "Spot") {
      if (correctAnswer) {
        const answers = correctAnswer.map((i) => checked[i - 1] == true);

        const allTrue = answers.map(Boolean).every((item) => item === true);
        console.log(allTrue);
        if (
          allTrue &&
          checked.filter((e) => e === true ?? e).length == correctAnswer.length
        ) {
          correct = true;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else if (questions.mode == "Shadow") {
      const allTrue = dropped.every((item) => item === true);
      if (allTrue) {
        correct = true;
      } else {
        return true;
      }
    } else if (questions.mode == "Collect") {
      let trueIndexes = [];

      dropped.forEach(function (element, index) {
        if (element === true) {
          trueIndexes.push(index);
        }
      });

      if (correctAnswer && trueIndexes.length == correctAnswer.length)
        correct = true;
      else return true;
    }

    if (correct) {
      correct = false;

      props.props.playSound("correct");
      if (sfx) {
        props.props.playSound("win");
      }
      playAnimation(4000);
      setIsTransitioning(true);
      setTimeout(() => {
        setIsTransitioning(false);
        // console.log(props.props.questions);
        // console.log(currentQuestion);
        // console.log(index);
        if (index < props.props.questions.length) {
          dispatch(setCurrentQuestion(index));

          //
        } else {
          nextLevel();
        }
      }, 2100);
    } else {
      props.props.playSound("wrong");
      dispatch(updateAttempts());
    }
  };

  const placeLayer = (layer, type) => {
    layer = JSON.parse(layer.data);
    let stageWidth = stage.current ? stage.current.offsetWidth : 1;
    const scaleRatio = 1; // = stageWidth / 1024; // Calculate the scale ratio based on the stage width
    let index = 0;
    if (type == "shadow") {
      index = 1;
    }

    layer[index].position.x = (layer[index].position.x * 100) / 1024;
    layer[index].position.y = (layer[index].position.y * 100) / 1024;
    layer[index].position.w = (layer[index].position.w * 100) / 1024;
    layer[index].position.h = (layer[index].position.h * 100) / 1024;

    return {
      left: layer[index].position.x + "%",
      top: layer[index].position.y + "%",
      width: layer[index].position.w * layer[index].scale.x + "%",
      height: layer[index].position.h * layer[index].scale.y + "%",
      transform: `rotate(${layer[index].rotation}deg)`,
    };
  };

  const [dropped, setDropped] = useState(questions.layers.map(() => false));
  const [checked, setChecked] = useState(questions.layers.map(() => false));
  const [activeId, setActiveId] = useState(null);
  const handleDragEnd = (event) => {
    const { over, active } = event;
    if (over) {
      handleDroppableDrop(active.id, over.id);
    }
    setActiveId(null);
  };

  // complete handleDroppableDrop
  const handleDroppableDrop = (itemId, droppableId) => {
    // console.log(itemId, droppableId);
    let droppedid = (parseInt(itemId.substring(2)) + 1).toString();
    // console.log(droppableId);
    // console.log(correctAnswer.includes(droppedid));
    if ("Collect" == mode && correctAnswer.includes(droppedid)) {
      const updatedDropped = dropped.map((value, index) =>
        index === droppedid - 1 ? true : value
      );
      setDropped(updatedDropped);
      // console.log(dropped);
    } else {
      if (itemId.substring(2).toString() == droppableId.toString()) {
        const updatedDropped = dropped.map((value, index) =>
          index === droppableId ? true : value
        );
        setDropped(updatedDropped);
      }
    }

    // console.log(dropped);
  };
  function handleDragStart(event) {
    setActiveId(event.active.id);
  }

  const setCheck = (clickedId) => {
    const updatedDropped = checked.map((value, index) =>
      index === clickedId ? !value : value
    );
    setChecked(updatedDropped);
    // console.log(clickedId);
  };

  useEffect(() => {
    handleAnswer(currentQuestion + 1);
  }, [dropped, checked]);

  return (
    <React.Fragment>
      <div className="container d-flex flex-column align-items-center justify-content-between h-100 w-100">
        <AnimatePresence mode="popLayout">
          {!isTransitioning && questions && (
            <motion.div
              key="stage"
              initial={{ x: "100vw" }}
              animate={{ x: "0px" }}
              exit={{ x: "-100vw" }}
              transition={{
                delay: 0,
                duration: 2,
                ease: "linear",

                // type: "normal",
                stiffness: 0,
              }}
              className="text-center text-md head d-flex flex-column align-items-center justify-content-center"
            >
              <div className="titles">{questions && questions.instruction}</div>

              {!audioError && (
                <motion.div
                  onClick={playSound}
                  initial={{ opacity: 0, visibility: "hidden" }}
                  exit={{ opacity: 0, visibility: "hidden" }}
                  animate={{ opacity: 1, visibility: "visible" }}
                  transition={{
                    delay: 1,
                    type: "tween",
                    duration: 0.4,
                  }}
                  className={`d-inline btn btn-primary circle mini volume`}
                ></motion.div>
              )}

              {questions && (
                <div className="w-full stage" ref={stage}>
                  {questions.image == "" ? (
                    <>
                      <img
                        src={`https://assets.dhvij.com/images/maps/placeholder.png`}
                        className="img-fluid"
                      />
                      <Tiles theme={theme} map={map} />
                    </>
                  ) : (
                    <img src={questions.image} className="img-fluid" />
                  )}
                  {questions.stage_images &&
                    questions.stage_images.map((img, j) => {
                      return (
                        <React.Fragment key={`k_${j}`}>
                          <div
                            style={placeLayer(img, "decor")}
                            className="layer decor"
                          >
                            <img className={`layer-img`} src={img.image} />
                          </div>
                        </React.Fragment>
                      );
                    })}

                  <DndContext
                    onDragEnd={handleDragEnd}
                    onDragStart={handleDragStart}
                  >
                    {questions.layers &&
                      questions.layers.map((j, count) => {
                        return (
                          !isTransitioning &&
                          j.image && (
                            <React.Fragment key={count}>
                              {dropped[count] !== true && (
                                <div
                                  style={placeLayer(j, "layer")}
                                  className="layer "
                                >
                                  {mode === "Spot" && (
                                    <>
                                      <img
                                        onClick={() => setCheck(count)}
                                        className={`layer-img  ${
                                          checked[count] == true ? "active" : ""
                                        }`}
                                        src={j.image}
                                      />
                                    </>
                                  )}
                                  {questions.mode !== "Spot" && (
                                    <>
                                      {questions.collector_id - 1 !== count ? (
                                        <>
                                          <Draggable
                                            stage={
                                              stage.current
                                                ? stage.current.offsetWidth
                                                : 1
                                            }
                                            id={`dr` + count}
                                            parent={
                                              count ==
                                                correctAnswer?.includes(
                                                  count
                                                ) ?? questions.collector_id
                                            }
                                          >
                                            <img
                                              className="layer-img"
                                              src={j.image}
                                            />
                                          </Draggable>
                                        </>
                                      ) : (
                                        <Droppable
                                          id={questions.collector_id}
                                          item={questions.collector_id}
                                          data-id={count}
                                          onDrop={() => handleDroppableDrop(j)}
                                        >
                                          <img
                                            key={count}
                                            className={`layer-img`}
                                            // style={placeLayer(j, "shadow")}
                                            src={j.image}
                                          />
                                        </Droppable>
                                      )}
                                    </>
                                  )}
                                </div>
                              )}

                              {mode == "Shadow" && (
                                <>
                                  <div
                                    className="droppable-handle"
                                    style={placeLayer(j, "shadow")}
                                  >
                                    <Droppable
                                      id={count}
                                      item={j}
                                      onDrop={() => handleDroppableDrop(j)}
                                    >
                                      <img
                                        key={count}
                                        className={`layer-img ${
                                          dropped[count] !== true ? "drop" : ""
                                        }`}
                                        // style={placeLayer(j, "shadow")}
                                        src={j.image}
                                        data-id={count}
                                      />
                                    </Droppable>
                                  </div>
                                </>
                              )}
                            </React.Fragment>
                          )
                        );
                      })}
                  </DndContext>
                </div>
              )}
            </motion.div>
          )}

          <div className="d-flex flex-row justify-content-start pages">
            <div
              className="btn btn-primary circle prev"
              area-label="Next"
              onClick={() => setScreen(3)}
            ></div>
          </div>
        </AnimatePresence>
      </div>
    </React.Fragment>
  );
}
