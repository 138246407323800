import React, { useState, useEffect } from "react";
import { slugify } from "../utils/Utils";
import { motion, AnimatePresence } from "framer-motion";
// import AudioPlayer from "./AudioPlayer";
import { Howl, Howler } from "howler";
import TagManager from "react-gtm-module";
import { useSelector, useDispatch } from "react-redux";
import { updateAttempts } from "../actions/levelStatsActions";
import { setGameStatus, setCurrentQuestion } from "../actions/gameActions";

export default function SelectImage(props) {
  // const [currentQuestion, setCurrentQuestion] = useState(0);
  const currentQuestion = useSelector((state) => state.game.currentQuestion);

  const [inputAnswer, setInputAnswer] = useState([]);
  const [correctAnswer, setCorrectAnswer] = useState();
  const {
    setScreen,
    nextLevel,
    currentLevel,
    setQuestionIndex,
    setQuestionLength,
  } = props.props;

  const [isTransitioning, setIsTransitioning] = useState();

  const [audio, setAudio] = useState(null);
  // const [isAudioLoaded, setIsAudioLoaded] = useState(false);
  const [audioError, setAudioError] = useState(false);
  const [sound, setSound] = useState(null);
  const { sfx } = useSelector((state) => state.sfx);
  const levelStats = useSelector((state) => state.levelStats);
  const dispatch = useDispatch();

  // console.log(props.props.questions)
  let questions = props.props.questions[currentQuestion];

  const playAnimation = (time = 2010) => {
    dispatch(setGameStatus("transitioning"));
    setTimeout(() => dispatch(setGameStatus("playing")), time);
  };
  useEffect(() => {
    playAnimation(2010);
  }, []);
  const playSound = () => {
    sound.stop();
    sound.play();
  };
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
        pageTitle: questions.instruction,
        pagePath: currentLevel,
        pageCategory: "Question",
      },
    });

    setupQuestion(currentQuestion);

    setQuestionIndex(currentQuestion);
    setQuestionLength(props.props.questions.length);

    setAudioError(false);

    const mp3 =
      "https://assets.dhvij.com/sounds/" +
      currentLevel +
      "/" +
      slugify(questions.instruction) +
      ".mp3";

    // const status =  checkFileExists(mp3)

    setAudio(mp3);
    setSound(
      new Howl({
        src: [mp3],
      })
    );
    var sound = new Howl({
      src: [mp3],
      onloaderror: () => setAudioError(true),
      onload: () => setAudioError(false),
    });
    if (sfx) {
      setTimeout(function () {
        sound.play();
      }, 1500);
    }
  }, [currentQuestion]);

  const setupQuestion = (question) => {
    // gets a question and sets answers to be checked at a later stage
    let answer = props.props.questions[currentQuestion].correct_answer
      .toString()
      .split(",");
    setCorrectAnswer(answer);
    setInputAnswer([]);
  };

  const toggleSelection = (e) => {
    e.target.classList.toggle("active");
    let key = e.target.dataset.key;
    let savedanswer = inputAnswer;
    if (!e.target.classList.contains("active")) {
      var index = savedanswer.indexOf(key);
      if (index !== -1) {
        savedanswer.splice(index, 1);
      }
    } else {
      savedanswer.push(key);
    }

    return handleAnswer(currentQuestion + 1) === false
      ? setTimeout(() => {
          e.target.classList.remove("active");
          setInputAnswer([]);
        }, 100)
      : null;
  };

  const handleAnswer = (index) => {
    let correct = false;
    let input = inputAnswer.sort();
    let answer = correctAnswer.sort();
    // console.log(
    //   JSON.stringify(inputAnswer.sort()),
    //   "==",
    //   JSON.stringify(correctAnswer.sort()),
    //   "==",
    //   index
    // );

    if (JSON.stringify(input) === JSON.stringify(answer)) {
      correct = true;
    } else {
      if (answer.some((r) => input.includes(r))) {
        return true;
      } else {
        props.props.playSound("wrong");
        return false;
      }
    }
    if (correct) {
      correct = false;
      // leaveQuestion()
      props.props.playSound("correct");
      if (sfx) {
        props.props.playSound("win");
        // winSound();
        // console.log("win");
      }
      playAnimation(4000);
      setIsTransitioning(true);
      setTimeout(() => {
        setIsTransitioning(false);

        if (index < props.props.questions.length) {
          // props.props.setQuestionIndex(index + 1);
          // setCurrentQuestion(index);
          dispatch(setCurrentQuestion(index));
        } else {
          nextLevel();
        }
      }, 2100);
      // setGameStatus("playing");
      return true;
    } else {
      props.props.playSound("wrong");
      dispatch(updateAttempts());
      return false;
    }
  };

  return (
    <React.Fragment>
      <div className="container d-flex flex-column align-items-center justify-content-between h-100 w-100">
        <AnimatePresence>
          {!isTransitioning && questions && (
            <>
              <motion.div
                key={`container`}
                initial={{ x: "100vw" }}
                animate={{ x: "0px" }}
                exit={{ x: "-100vw" }}
                transition={{
                  delay: 0,
                  duration: 2,
                  ease: "linear",

                  // type: "normal",
                  stiffness: 0,
                }}
                className="text-center text-md  d-flex flex-column align-items-center justify-content-center"
              >
                <div className="head">
                  {questions && questions.instruction}{" "}
                  {!audioError && (
                    <motion.div
                      key={`audio`}
                      onClick={playSound}
                      initial={{ opacity: 0, visibility: "hidden" }}
                      exit={{ opacity: 0, visibility: "hidden" }}
                      animate={{ opacity: 1, visibility: "visible" }}
                      transition={{
                        delay: 1,
                        type: "tween",
                        duration: 0.4,
                      }}
                      className={`d-inline btn btn-primary circle mini volume`}
                    ></motion.div>
                  )}{" "}
                  {questions && questions.instruction_image && (
                    <div className="text-center thebox fit">
                      <img src={questions.instruction_image} />
                    </div>
                  )}
                </div>
                <div className="dh-grid v2 ">
                  {questions.images &&
                    questions.images.map((j, count) => {
                      return (
                        !isTransitioning && (
                          <div
                            key={j + count}
                            className="d-flex flex-row justify-content-between item"
                            onClick={(e) => {
                              toggleSelection(e);
                            }}
                            data-key={count + 1}
                          >
                            <div className="text-center d-flex flex-column pe-none w-100">
                              <div className="text-center thebox pe-none drag">
                                {j.image && <img src={j.image} />}
                                {j.name && (
                                  <div className="text-center ">{j.name}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )
                      );
                    })}
                </div>
              </motion.div>
            </>
          )}
        </AnimatePresence>
      </div>
    </React.Fragment>
  );
}
