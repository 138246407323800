// gameReducer.js

const initialState = {
  gameStatus: "playing", // Possible values: 'idle', 'playing', 'paused', 'gameOver'
  currentPage: "titles",
  transitioning: false,
  currentQuestion: 0,
  nextQuestion: 0,
  currentLevel: 1,
  nextLevel: 0,
  questionType: "select_image",
  currentTheme: 0,
  nextLevel: 2,
  sfxStatus: true,
  sfxVolume: 50,
  musicStaus: true,
  musicVolume: 50,
};

const gameReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_TRANSITIONING":
      return {
        ...state,
        transitioning: action.payload,
      };
    case "SET_GAME_STATUS":
      return {
        ...state,
        gameStatus: action.payload,
      };
    case "SET_CURRENT_PAGE":
      return {
        ...state,
        currentPage: action.payload,
      };
    case "SET_CURRENT_QUESTION":
      return {
        ...state,
        currentQuestion: action.payload,
      };
    case "SET_NEXT_QUESTION":
      return {
        ...state,
        nextQuestion: action.payload,
      };
    case "SET_CURRENT_LEVEL":
      return {
        ...state,
        currentLevel: action.payload,
      };
    case "SET_NEXT_LEVEL":
      return {
        ...state,
        nextLevel: action.payload,
      };
    case "SET_QUESTION_TYPE":
      return {
        ...state,
        questionType: action.payload,
      };
    case "SET_CURRENT_THEME":
      return {
        ...state,
        currentTheme: action.payload,
      };
    case "SET_NEXT_LEVEL":
      return {
        ...state,
        nextLevel: action.payload,
      };
    case "SET_SFX_STATUS":
      return {
        ...state,
        sfxStatus: action.payload,
      };
    case "SET_SFX_VOLUME":
      return {
        ...state,
        sfxVolume: action.payload,
      };
    case "SET_MUSIC_STATUS":
      return {
        ...state,
        musicStatus: action.payload,
      };
    case "SET_MUSIC_VOLUME":
      return {
        ...state,
        musicVolume: action.payload,
      };
    default:
      return state;
  }
};

export default gameReducer;
