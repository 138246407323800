// src/reducers/currentLevelReducer.js

// Define the initial state
const initialState = {
    currentLevel: 1
  };
  
  // Define the reducer function
  const currentLevelReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_CURRENT_LEVEL":
        return { ...state, currentLevel: action.payload };
      default:
        return state;
    }
  };
  
  export default currentLevelReducer;
  