import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

export default function DragWord(props) {
  //let images = props.data;

  const [images, setImages] = useState(props.data);
  const { isTransitioning, setIsTransitioning } = props.props;
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [inputAnswer, setInputAnswer] = useState([]);
  const [correctAnswer, setCorrectAnswer] = useState(images);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // Call your enterQuestion function
    // enterQuestion();

    // Shuffle the names of the images array, checking for duplicates
    const names = images.map((obj) => obj.name);
    let shuffledNames = names.slice().sort(() => Math.random() - 0.5);
    while (shuffledNames.some((name, i) => name === names[i])) {
      shuffledNames = names.slice().sort(() => Math.random() - 0.5);
    }

    // Create a new array with shuffled names
    const shuffledImages = images.map((obj, i) => {
      return { ...obj, name: shuffledNames[i] };
    });

    // Update state with the shuffled images
    setImages(shuffledImages);

    // Set input answer with the shuffled images
    setInputAnswer(shuffledImages);

    const delay = setTimeout(() => {
      setLoading(false);
    }, 10);
    return () => clearTimeout(delay);
  }, [correctAnswer]);

  const handleAnswer = (index) => {
    let correct = true;

    //   console.warn('correct');
    //  console.log(correctAnswer);
    //  console.warn('input');
    //  console.log(inputAnswer);

    // console.log(JSON.stringify(inputAnswer.sort()) ,' ', JSON.stringify(correctAnswer.sort()));

    //  console.log( JSON.stringify(inputAnswer.sort()) == JSON.stringify(correctAnswer.sort()));

    if (
      JSON.stringify(inputAnswer.sort()) != JSON.stringify(correctAnswer.sort())
    )
      correct = false;

    // setIsTransitioning(()=>true);
    if (correct) {
      correct = false;

      props.props.playSound("win");
      setIsTransitioning(() => true);
      setTimeout(() => {
        setIsTransitioning(false);
        setCurrentQuestion(index);
        setInputAnswer([]);
        props.setCurrentSection(props.currentSection + 1);
      }, 2000);
    } else {
      props.playSound("wrong");
    }
  };

  const getListStyle = (isDraggingOver) => ({
    boxShadow: isDraggingOver ? "0 0 0 5px #025589" : "none",
  });

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    let orignal = images;
    let newimages = [];
    orignal.map((e, i) => {
      if (i == result.source.index) {
        e.name = result.destination.droppableId;
      }
      if (i == result.destination.index) {
        e.name = result.source.droppableId;
      }
      newimages.push(e);
    });

    //console.log(inputAnswer)
    setImages(newimages);
    // setInputAnswer(images);
  };

  return (
    <React.Fragment>
      <AnimatePresence key={`dragword`}>
        <DragDropContext onDragEnd={onDragEnd}>
          <div className=" d-flex flex-row justify-content-between flex-wrap">
            {!isTransitioning &&
              !loading &&
              images.map(({ id, image, name }, count) => {
                return (
                  <div
                    key={count}
                    className="dragsection d-flex flex-row justify-content-between"
                  >
                    <div
                      targetkey={count}
                      className="text-center  d-flex flex-column w-100"
                    >
                      <Droppable droppableId={name} index={count} key={count}>
                        {(provided, snapshot) => (
                          <motion.div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            initial={{ opacity: 0, visibility: "hidden" }}
                            exit={{ opacity: 0, visibility: "hidden" }}
                            animate={{ opacity: 1, visibility: "visible" }}
                            transition={{
                              delay: 3000 * { count },
                              type: "tween",
                              duration: 0.4,
                            }}
                          >
                            <div
                              className="text-center thebox drag"
                              style={getListStyle(snapshot.isDraggingOver)}
                            >
                              <img
                                key={count}
                                src={image}
                                // onClick={(e) => e.target.classList.toggle("active")}
                              />
                            </div>

                            <Draggable
                              key={name}
                              draggableId={name}
                              index={count}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={
                                    snapshot.isDragging ? "dragging" : ""
                                  }
                                >
                                  <motion.div
                                    key={`drag${count}`}
                                    initial={{ opacity: 0, scale: 0 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    transition={{
                                      delay: 0,
                                      type: "spring",
                                      stiffness: 100,
                                    }}
                                    className={`text-center thebox spell dragger`}
                                  >
                                    <div
                                      className="value"
                                      style={{ "--l": `${name.length}` }}
                                    >
                                      {name}
                                    </div>
                                  </motion.div>
                                </div>
                              )}
                            </Draggable>
                            {provided.placeholder}
                          </motion.div>
                        )}
                      </Droppable>
                    </div>
                  </div>
                );
              })}
          </div>
        </DragDropContext>
        <motion.div
          key={`pagination`}
          initial={{ opacity: 0, visibility: "hidden" }}
          animate={{ opacity: 1, visibility: "visible" }}
          transition={{
            delay: 2 + 1 * 0.3,
            type: "tween",
            duration: 0.4,
          }}
          className="d-flex flex-row justify-content-between pages"
        >
          {currentQuestion !== 0 ? (
            <div
              className="btn btn-primary circle prev"
              area-label="Previous"
              onClick={() => setCurrentQuestion(currentQuestion - 1)}
            ></div>
          ) : (
            <div></div>
          )}
          {currentQuestion !== images.length - 1 && (
            <div
              className="btn btn-primary circle next"
              area-label="Next"
              onClick={() => handleAnswer(currentQuestion + 1)}
            ></div>
          )}

          {currentQuestion === images.length - 1 &&
            props.currentSection < props.totalSections - 1 && (
              <div
                className="btn btn-primary circle next"
                area-label="Next Chapter"
                onClick={() => {
                  handleAnswer(currentQuestion + 1);
                  // props.setCurrentSection(props.currentSection + 1);
                }}
              ></div>
            )}
        </motion.div>
      </AnimatePresence>
    </React.Fragment>
  );
}
