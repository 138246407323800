import React, { useState, useEffect, useRef } from "react";

import { motion, AnimatePresence } from "framer-motion";

export default function PictureSpell(props) {
  const spelling = props.data;
  const { isTransitioning, setIsTransitioning } = props.props;
  const inputRef = useRef(null);

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [inputAnswer, setInputAnswer] = useState([]);
  const [correctAnswer, setCorrectAnswer] = useState();

  //  console.log(props.props)

  useEffect(() => {
    //sets the current question
    setupQuestion(currentQuestion);

    // setAudioIndex(zeroPad(5))
  }, [currentQuestion]);

  const setupQuestion = (question) => {
    // gets a question and sets answers to be checked at a later stage
    let answer = spelling[question].imagec.name.toLowerCase();
    let answerArray = [];
    for (const c of answer) {
      answerArray.push(c);
    }
    setCorrectAnswer(answerArray);

    //create blank answer from correct answer and removed letters if letter is blank
    let blankAnswer = answerArray.map((v, i) => {
      return v;
    });

    // finds blanks and removes them from the answer
    let letters = spelling[question].words;

    letters.map((l, index) => {
      if (l === "") {
        blankAnswer[index] = "";
      }
      return;
    });

    setInputAnswer(blankAnswer);
  };

  const handelInvalid = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const regex = /^[a-zA-Z]*$/; // only allow numbers

    if (!regex.test(keyValue)) {
      event.preventDefault();
    }
  };

  const handleInputChange = (event) => {
    // const regex = /^[a-zA-Z]*$/; // regular expression to match alphanumeric characters only
    const inputValue = event.target.value;
    const inputIndex = event.target.getAttribute("data-key");

    if (8 === event.keyCode) {
      if (event.target.parentNode.previousSibling) {
        let prevInput =
          event.target.parentNode.previousSibling.querySelector("input");
        if (prevInput) prevInput.focus();
      }
    } else {
      // if (inputValue === "" || regex.test(inputValue)) {
      let input = inputAnswer;
      input[inputIndex] = inputValue;
      setInputAnswer(input);
      let nextInput =
        event.target.parentNode.nextSibling.querySelector("input");
      if (nextInput) nextInput.focus();
      //   }
    }
  };

  //checks the answer and proceeds to next screen
  const handleAnswer = (index) => {
    let correct = true;
    correctAnswer.map((val, i) => {
      if (inputAnswer[i].toLowerCase() !== val.toLowerCase()) correct = false;
    });

    // setIsTransitioning(()=>true);
    if (correct) {
      props.props.playSound("win");

      setIsTransitioning(() => true);
      setTimeout(() => {
        setIsTransitioning(false);
        setCurrentQuestion(index);
        setInputAnswer([]);
        correct = false;
      }, 2000);
    } else {
      props.props.playSound("wrong");
    }
  };

  return (
    <>
      {spelling.map((j, count) => {
        if (count === currentQuestion) {
          return (
            <AnimatePresence key={count}>
              {!isTransitioning && (
                <motion.div
                  exit={{ opacity: 0, scale: 0 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ delay: 1, type: "spring", stiffness: 100 }}
                  key={j}
                  className="picspell d-flex flex-column flex-lg-row justify-content-center align-items-center justify-content-md-between w-100"
                >
                  <div className="d-flex flex-row justify-content-md-between justify-content-center ">
                    <motion.div
                      initial={{ opacity: 0, scale: 0 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{ delay: 1, type: "spring", stiffness: 100 }}
                    >
                      <div className="text-center thebox single">
                        <img
                          key={j}
                          src={j.imagec.image}
                          onClick={(e) => e.target.classList.toggle("active")}
                        />
                      </div>
                    </motion.div>
                  </div>

                  <div className="text-center d-flex justify-content-md-center justify-content-lg-between">
                    {j.words &&
                      j.words.map((e, count) => {
                        return (
                          <motion.div
                            key={count}
                            initial={{ opacity: 0, scale: 0 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{
                              delay: 2 + count * 0.2,
                              type: "spring",
                              stiffness: 100,
                            }}
                            className="thebox"
                          >
                            {e === "" && (
                              <input
                                ref={inputRef}
                                key={count}
                                data-key={count}
                                onKeyPress={(e) => handelInvalid(e)}
                                onKeyUp={(e) => handleInputChange(e)}
                                pattern="[A-Za-z]"
                                inputMode="text"
                                maxLength="1"
                              />
                            )}

                            {e !== "" && <div className="value">{e}</div>}
                          </motion.div>
                        );
                      })}
                  </div>
                </motion.div>
              )}
              <motion.div
                initial={{ opacity: 0, visibility: "hidden" }}
                animate={{ opacity: 1, visibility: "visible" }}
                transition={{
                  delay: 2 + count * 0.3,
                  type: "tween",
                  duration: 0.4,
                }}
                className="d-flex flex-row justify-content-between pages"
              >
                {currentQuestion !== 0 ? (
                  <div
                    className="btn btn-primary circle prev"
                    area-label="Previous"
                    onClick={() => setCurrentQuestion(currentQuestion - 1)}
                  ></div>
                ) : (
                  <div></div>
                )}
                {currentQuestion !== spelling.length - 1 && (
                  <div
                    className="btn btn-primary circle next"
                    area-label="Next"
                    onClick={() => handleAnswer(currentQuestion + 1)}
                  ></div>
                )}

                {currentQuestion === spelling.length - 1 &&
                  props.currentSection < props.totalSections - 1 && (
                    <div
                      className="btn btn-primary circle next"
                      area-label="Next Chapter"
                      onClick={() => {
                        props.setCurrentSection(props.currentSection + 1);
                      }}
                    ></div>
                  )}
              </motion.div>
            </AnimatePresence>
          );
        }
        return null;
      })}
    </>
  );
}
