import React, { useState, useRef, useEffect } from "react";
import DButton from "./DButton";
import { Scrollbar } from "smooth-scrollbar-react";
import {  InView} from 'react-intersection-observer';
import Planet from "./Planet";



import { ScrollContainer,HorizontalSection,useGlobalState } from 'react-nice-scroll';
import 'react-nice-scroll/dist/styles.css';

 
export default function Category(props) {
  const [levels, setLevels] = useState(props.levels);
   const {onClick,playSound} = props
    const [loading,setLoading] = useState(true)


   useEffect(()=>{
    setLoading(false)
    // console.log(smoothScrollBar)
   },[])
// const onClick = (e)=> console.log(e)

const [smoothScrollBar] = useGlobalState('smoothScrollBar');

// const font = useFont('fonts/Outfit-Medium.ttf');

const [visibleIndexes, setVisibleIndexes] = useState(() => Array.from({ length: levels.length }, () => false));


const handleChange = (inView, entry, index) => {

 if (inView && !visibleIndexes[index]) {
   setVisibleIndexes((prevVisibleIndexes) => {
     const newVisibleIndexes = [...prevVisibleIndexes];
     newVisibleIndexes[index] = true;
     return newVisibleIndexes;
   });
 } else if (!inView && visibleIndexes[index]) {
   setVisibleIndexes((prevVisibleIndexes) => {
     const newVisibleIndexes = [...prevVisibleIndexes];
     newVisibleIndexes[index] = false;
     return newVisibleIndexes;
   });
 }
};



  return (
     <React.Fragment>
        


         {/* <ScrollContainer> */}

         {/* <HorizontalSection> */}

     <div className="min-vw-100" style={{overflowY:'auto', overflowX:'hidden' ,transformStyle:'preserve-3d', perspective:'1200px'}}>
   
     {/* <div className="drop0" style={{height:`calc(${levels.length} * 55vh)`}}></div> */}
     <div className="drop1" style={{height:`calc(${levels.length} * 55vh)`}}></div>
     <div className="drop2" style={{height:`calc(${levels.length} * 55vh)`}}></div>
     <div className="drop3" style={{height:`calc(${levels.length} * 55vh)`}}></div>
   
              <div className="position-relative grid planets" >
    
                {levels &&
                  levels.map((e, i) => {
                    return (
                      <InView
                      className={`${visibleIndexes[i] ? 'visible' : ''}`}
                      delay={100}
                      threshold={0.1}
                      key={`inview-${i}`} onChange={(inView, entry) => handleChange(inView, entry, i)}>
                   
                       <Planet playSound={playSound} id={e.id} index={i} title={e.title} cover={e.cover} onClick={onClick}/>
                         </InView>
                    );
                  })}
     
 
           
        </div>
        </div>
        {/* </HorizontalSection> */}
          {/* </ScrollContainer>  */}
        
        

     </React.Fragment>
  );
}
