import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { TypeAnimation } from "react-type-animation";

export default function Heading(props) {
  const { i, count, isTransitioning } = props;

  return (
    <>
      {i.instruction && !isTransitioning && (
        <motion.div
          key={"t" + count}
          exit={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 5, type: "spring", stiffness: 100 }}
          className="head"
        >
          {i.instruction}
        </motion.div>
      )}
    </>
  );
}
