import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  leaveQuestion,
  enterQuestion,
  wrongAnswer,
  slugify,
} from "../utils/Utils";

import { Howl, Howler } from "howler";
import TagManager from "react-gtm-module";

import { useSelector, useDispatch } from "react-redux";
import { updateAttempts } from "../actions/levelStatsActions";
import { setGameStatus, setCurrentQuestion } from "../actions/gameActions";

export default function Connection(props) {
  const [rightSide, setRightSide] = useState(props.data[1]);
  const [leftSide, setLeftSide] = useState(props.data[0]);
  const [data, setData] = useState(props.data);

  const {
    isTransitioning,
    setIsTransitioning,
    setQuestionIndex,
    setQuestionLength,
    currentLevel,
    totalSections,
  } = props.props;
  // const [currentQuestion, setCurrentQuestion] = useState(0);
  const currentQuestion = useSelector((state) => state.game.currentQuestion);

  const [inputAnswer, setInputAnswer] = useState([]);
  const [correctAnswer, setCorrectAnswer] = useState(props.correct);
  const [loading, setLoading] = useState(true);
  const { nextLevel } = props;
  const { setScreen } = props.props;

  const [audio, setAudio] = useState(null);
  const [isAudioLoaded, setIsAudioLoaded] = useState(false);
  const [audioError, setAudioError] = useState(false);
  const { sfx } = useSelector((state) => state.sfx);
  const levelStats = useSelector((state) => state.levelStats);
  const dispatch = useDispatch();

  useEffect(() => {
    //sets the current question
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
        pageTitle: props.props.questions[currentQuestion].instruction,
        pagePath: props.currentSection,
        pageCategory: "Question",
      },
    });

    setQuestionIndex(currentQuestion);
    setQuestionLength(props.props.questions.length);

    const shuffledRightSide = shuffleArray([...data[1]]);
    // const shuffledLeftSide = shuffleArray([...data[0]]);

    setData([data[0], shuffledRightSide]);
    setInputAnswer([data[0], shuffledRightSide]);
  }, [currentQuestion]);

  const shuffleArray = (arr) => {
    const newArr = [...arr];
    for (let i = newArr.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArr[i], newArr[j]] = [newArr[j], newArr[i]];
    }
    return newArr;
  };

  const playSound = (e) => {
    if (!sfx) return;
    // console.log(props.props.questions[props.currentSection].instruction)
    const obj = slugify(e.target.innerText);
    const mp3 =
      "https://assets.dhvij.com/sounds/" + currentLevel + "/" + obj + ".mp3";
    var sound = new Howl({ src: [mp3] });
    sound.play();
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      setLoading(false);
    }, 10);
    return () => clearTimeout(delay);
  }, []);

  const handleAnswer = (index) => {
    let correct = true;

    if (
      JSON.stringify(inputAnswer.sort()) != JSON.stringify(correctAnswer.sort())
    )
      correct = false;

    // setIsTransitioning(()=>true);
    if (correct) {
      correct = false;
      // leaveQuestion();
      if (sfx) props.props.playSound("win");
      props.props.playSound("correct");
      setIsTransitioning(() => true);
      setTimeout(() => {
        setIsTransitioning(false);
        // console.log(props.currentSection + " = "+ props.props.questions.length)
        if (index < props.props.questions.length) {
          // setCurrentQuestion(index);

          setInputAnswer([]);
          dispatch(setCurrentQuestion(index));
          //   props.setCurrentSection(props.currentSection + 1);
          // setQuestionIndex(index)
        } else {
          nextLevel();
        }
      }, 2100);
    } else {
      // props.props.playSound("wrong");
      dispatch(updateAttempts());
    }
  };

  const getListStyle = (isDraggingOver) => ({
    boxShadow: isDraggingOver ? "0 0 0 5px #025589" : "none",
  });

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    let orignal = data[1];
    let temp = orignal[result.destination.index];
    orignal[result.destination.index] = orignal[result.source.index];
    orignal[result.source.index] = temp;

    setData([data[0], orignal]);

    //console.log(inputAnswer)
    //  setData(newimages);
    setInputAnswer([data[0], orignal]);
    handleAnswer(currentQuestion + 1);
  };

  const options = {
    // ...
    touchSlop: 10,
    // ...
  };
  const bodyRef = React.useRef(null);

  return (
    <React.Fragment>
      <DragDropContext
        dragDelay={0}
        onDragEnd={onDragEnd}
        {...options}
        scrollContainerStyle={{ overflow: "auto" }}
        containerRef={bodyRef}
        dragStartThreshold={0}
        enableTouchEvents
        touchStartThreshold={0}
        touchSlop={0}
      >
        <div className="dh-grid" ref={bodyRef}>
          <AnimatePresence key={`dragword`}>
            {!isTransitioning &&
              !loading &&
              data[0].map(({ image, name }, count) => {
                return (
                  <div
                    key={count}
                    className="dragsection d-flex0 flex-row justify-content-between"
                  >
                    <div
                      targetkey={count}
                      className="text-center  d-flex0 flex-column"
                    >
                      <Droppable
                        droppableId={`d${count}`}
                        index={count}
                        key={`dg${count}`}
                      >
                        {(provided, snapshot) => (
                          <motion.div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            initial={{ opacity: 0, visibility: "hidden" }}
                            exit={{ opacity: 0, visibility: "hidden" }}
                            animate={{ opacity: 1, visibility: "visible" }}
                            transition={{
                              delay: 0.3 * count,
                              type: "tween",
                              duration: 0.4,
                            }}
                          >
                            <div
                              onMouseDown={playSound}
                              className="text-center thebox drag spell"
                              style={getListStyle(snapshot.isDraggingOver)}
                            >
                              {image != "" ? (
                                <img key={count} src={image} />
                              ) : (
                                <div
                                  className="value pe-none"
                                  style={{ "--l": `${name.length}` }}
                                >
                                  {name}
                                </div>
                              )}
                            </div>

                            <Draggable
                              key={`d${data[1][count].name}`}
                              draggableId={`d${count}`}
                              index={parseInt(count)}
                              dragDelay={0}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={
                                    snapshot.isDragging ? "dragging" : ""
                                  }
                                >
                                  <motion.div
                                    key={`drag${count}`}
                                    initial={{ opacity: 0, scale: 0 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    transition={{
                                      delay: 0,
                                      type: "spring",
                                      stiffness: 100,
                                    }}
                                    onMouseDown={playSound}
                                    className={`text-center thebox drag spell`}
                                  >
                                    {data[1][count].image != "" ? (
                                      <img
                                        key={count}
                                        src={data[1][count].image}
                                      />
                                    ) : (
                                      <div
                                        className="value pe-none"
                                        onMouseDown={playSound}
                                      >
                                        {data[1][count].name}
                                      </div>
                                    )}
                                  </motion.div>
                                </div>
                              )}
                            </Draggable>
                            {provided.placeholder}
                          </motion.div>
                        )}
                      </Droppable>
                    </div>
                  </div>
                );
              })}
          </AnimatePresence>
        </div>
      </DragDropContext>
    </React.Fragment>
  );
}
