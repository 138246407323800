import { AnimatePresence,motion } from 'framer-motion'
import React from 'react'
import DButton from './DButton'

import { useSelector, useDispatch } from 'react-redux';


export default function Settings(props) {
    const {playSound} = props;
    const {backgroundMusic} = useSelector(state => state.backgroundMusic);
    const {sfx} = useSelector(state => state.sfx);
   // const sfx = useSelector(state => state.sfx);
   // const currentLevel = useSelector(state => state.currentLevel);

    const dispatch = useDispatch();

  const handleBackgroundMusicChange = () => {
    dispatch({ type: 'TOGGLE_BACKGROUND_MUSIC', payload: !backgroundMusic });
  };
    const handleSfxChange = () => {
    dispatch({ type: 'TOGGLE_SFX', payload: !sfx });
  };


    return (
       <React.Fragment>
        <AnimatePresence>
            <div className='position-fixed w-100 h-screen appStopper'>
                <motion.div
                  initial={{opacity: 0, scale:0}}
                  exit={{opacity: 0, scale:0}}  
                  animate={{ opacity: 1, scale:1  }}
                  transition={{ delay: 0, type: "spring", stiffness: 100 }}
                className='dialog'>
                    
              

                    <DButton playSound={playSound} onClick={()=>props.setHasNav(false)} className="dialog-button circle mini cancel" ></DButton>
                    
                    <div className='d-flex flex-column justify-content-center align-items-center'>

                    <h1>{backgroundMusic}</h1>
      {/* <h1>{sfx}</h1> */}
      {/* <h1>{currentLevel}</h1> */}
<DButton playSound={playSound}  onClick={handleBackgroundMusicChange} className={`circle long`}>Music : {backgroundMusic?`ON`:`OFF`}</DButton>

<DButton playSound={playSound} onClick={handleSfxChange} className={`circle long`}>Voices : {sfx?`ON`:`OFF`}</DButton>

{/* <DButton onClick={()=>{}} className={`circle long`}>Sound</DButton> */}
                    </div>
                </motion.div>
                </div>
        </AnimatePresence>
       </React.Fragment>
    )
}
