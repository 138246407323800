import React from "react";
import data from "../utils/maps.json";

export default function Tiles(props) {
  const { map, theme } = props;
  const getStyle = (map) => {
    return data[map];
  };

  const generateCSSObjects = (mapid) => {
    const objectStyles = [];
    const map = getStyle(mapid);
    map.map((e, i) => {
      const style = {
        position: "absolute",
        width: `${(e.width * 100) / 1080}%`,
        height: "5%",
        top: `${(e.y * 100) / 1080}%`,
        left: `${(e.x * 100) / 1080}%`,
      };

      objectStyles.push(<div className="tile" key={i} style={style}></div>);
    });

    return objectStyles;
  };

  return (
    <>
      <div className={`square theme-${theme}`}>
        {generateCSSObjects(map)}
        {/* {tiles.map((e, count) => {
          <div className="platform" style={() => getStyle(count)}></div>;
        })} */}
      </div>
    </>
  );
}
