import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';

import ErrorBoundary from './components/ErrorBoundary';
import store from "./store";
import { Provider } from "react-redux";


import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Test from './Test';
import AppContainer from './AppContainer';



const root = ReactDOM.createRoot(document.getElementById('root'));

// const setTheme = (theme)=>{
//   console.log(`settingtheme ${theme}`)
// }

const router = createBrowserRouter([
  {
    path: "/",
    element: <><AppContainer/></>,
  },
  {
    path: "/standalone/:id",
    element: <><AppContainer/></>,
  },

]);

 

root.render(
  <React.StrictMode>
        <Provider store={store}>

      <ErrorBoundary>
      <RouterProvider router={router} />
      </ErrorBoundary>
      </Provider>
  </React.StrictMode>
);

// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
