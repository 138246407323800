// src/reducers/index.js

import backgroundMusicReducer from "./backgroundMusicReducer";
import currentLevelReducer from "./currentLevelReducer";
import sfxReducer from "./sfxReducer";
import levelStatsReducer from "./levelStatsReducer";
import userReducer from "./userReducer";
import gameReducer from "./gameReducer";

import { combineReducers } from "redux";

const rootReducer = combineReducers({
  game: gameReducer,
  backgroundMusic: backgroundMusicReducer,
  currentLevel: currentLevelReducer,
  sfx: sfxReducer,
  levelStats: levelStatsReducer,
  user: userReducer,
});

export default rootReducer;
