import React from "react";
import { useDroppable } from "@dnd-kit/core";

export function Droppable(props) {
  const { isOver, setNodeRef } = useDroppable({
    id: props.id,
  });

  let { item } = props;

  const style = {
    filter: isOver ? " brightness(2)" : undefined,
    top: item.top + "px",
    left: item.left + "px",
    width: item.width + "px",
    height: item.height + "px",
  };

  return (
    <div className={props.className} ref={setNodeRef} style={style}>
      {props.children}
    </div>
  );
}
